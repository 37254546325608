import { React, useEffect, useState } from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import { DataGrid } from '@mui/x-data-grid'
import { motion } from 'framer-motion'

export default function DataGridDemo() {
  const [projects, getProjects] = useState([])
  // const [rows] = useState([])
  // const [columns] = useState([])
  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'ProjectNameAR',
      headerName: 'اسم المشروع ',
      width: 150,
      editable: false,
    },
    {
      field: 'IdeaInfo',
      headerName: 'نبذه عن المشروع',
      width: 150,
      editable: false,
    },
    {
      field: 'Target',
      headerName: 'الهدف',
      type: 'number',
      width: 100,
      editable: false,
    },
    {
      field: 'UpdateDateTime',
      headerName: 'وقت التسجيل',
      type: 'dateTime',
      width: 100,
      editable: false,
    },
    {
      field: '',
      headerName: '',
      type: 'number',
      width: 100,
      editable: false,
    },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
  ]
  const rows = projects
  useEffect(() => {
    // document.title = 'Project Name | Mmol'
    // let getProject = getproject(projectid)
    async function getProjectsfun() {
      let result = await fetch('https://api.momoel.com/api/project/view/all')
      result = await result.json()
      getProjects(result)
    }
    getProjectsfun()
  }, [])
  return (
    <>
      <motion.div
        className="CRMPAGE" //layoutId="underline"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="pagetitle">الزوار</div>
        {projects ? (
          <>
            <Box sx={{ height: '90%', width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[5]}
                checkboxSelection
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
              />
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ height: '90%', width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[5]}
                checkboxSelection
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
              />
            </Box>
          </>
        )}
      </motion.div>
    </>
  )
}
