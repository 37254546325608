import { React, useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import '../style/profile.css'
import { motion } from 'framer-motion'
import Cookies from 'universal-cookie'
if (!localStorage.getItem('userimg')) {
  localStorage.setItem(
    'userimg',
    JSON.stringify({
      ImageSrc: 'avatar.png',
    }),
  )
}
export default function Profile() {
  const goto = useNavigate()
  let user = JSON.parse(localStorage.getItem('userInfo'))
  let userimg = JSON.parse(localStorage.getItem('userimg'))

  function userpremetions() {
    if (user.GroupId) {
      switch (user.GroupId) {
        case '0':
          return 'Banana is good!'
          break
        case '1':
          return 'I am not a fan of orange.'
          break
        case '2':
          return 'How you like them apples?'
          break
        case '3':
          return 'How you like them apples?'
          break
        case '4':
          return 'How you like them apples?'
          break
        case '5':
          return 'How you like them apples?'
          break
        case '6':
          return 'How you like them apples?'
          break
        case '7':
          return 'How you like them apples?'
          break
        default:
          return 'I have never heard of that fruit...'
      }
    }
  }
  const cookies = new Cookies()
  var token = cookies.get('_user')
  // fetch user info
  var options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Origin: '',
      // Host: 'api.producthunt.com',
      Authorization: `Bearer ${token}`,
      Connection: 'keep-alive',
    },
    body: JSON.stringify({
      // client_id: '(API KEY)',
      // client_secret: '(API SECRET)',
      grant_type: 'client_credentials',
    }),
  }
  fetch('https://api.momoel.com/api/user/info/session', options)
    .then(function (res) {
      return res.json()
    })
    .then(function (resJson) {
      return resJson
    })

  // get user project
  const [projects, getProjects] = useState([])
  // page use state
  useEffect(() => {
    if (!localStorage.getItem('userInfo')) {
      goto('/')
    }
    async function getProjectsfun() {
      let result = await fetch(
        'https://api.momoel.com/api/project/view/user/' + user?.id,
      )
      result = await result.json()
      getProjects(result)
    }
    getProjectsfun()
  }, [])

  // open acording
  const openCity = (cityName, event) => {
    var i, tabcontent, tablinks
    tabcontent = document.getElementsByClassName('tabcontent')
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none'
    }
    tablinks = document.getElementsByClassName('tablinks')
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '')
    }
    if (document.getElementById(cityName)) {
      const box = document.getElementById(cityName)
      box.style.display = 'block'
    }
    // console.log('this is:', event)
    event.currentTarget.className += ' active'
  }
  // const [projectsviewAR] = useState([])
  // const [projectsview] = useState([])
  // projects view in English
  // if (!projects.result == 'NotAvailable') {
  // projects view in English
  // if (projects.length > 0) {
  // const projectsview = projects?.map((project) => (
  //   <NavLink to={'/project/' + project.id} key={project.id}>
  //     <div className="project">
  //       <div className="projectimg">
  //         <img src={require('../img/Project-BG-2005.jpg')} alt="" />
  //       </div>
  //       <div className="project_name">
  //         <p className="p_title">{project.project.ProjectName}</p>
  //         <p>{project.project.IdeaInfo}</p>
  //         <div className="project_progress">
  //           <span
  //             className="progress_in"
  //             style={{
  //               width: project.projectclc * 100 + '%',
  //             }}
  //           ></span>
  //         </div>
  //         <p>
  //           {project.currency.Code}
  //           {project.projectclc} / {project.currency.Code}
  //           {project.project.Target}
  //         </p>
  //       </div>
  //     </div>
  //   </NavLink>
  // ))
  // projects view in Arabic

  // const projectsviewAR =
  // }
  // update profile options and functions
  const [FirstName, setFirstname] = useState(user?.FirstName)
  const [LastName, setLastname] = useState(user?.LastName)
  const id = user?.id
  async function updateuserinfo() {
    let profile = { id, FirstName, LastName }
    let url = 'https://api.momoel.com/api/user/edit'
    let result = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(profile),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    result = await result.json()

    if (result.state === 'updated') {
      localStorage.setItem('userInfo', JSON.stringify(result.user))
      goto('/profile')
    }
  }

  // change user img
  const [profileImage, changeprofileImageval] = useState([])

  const setimageinprofile = (event) => {
    // console.log(URL.createObjectURL(event.target.files[0]))
    document.getElementById('profileImagepreview').src = URL.createObjectURL(
      event.target.files[0],
    )
    changeprofileImageval(event.target.files[0])
    document.getElementById('insendclick').classList.remove('inactive')
    document.getElementById('insendclick').classList.add('active')
    document.getElementById('inprogress').classList.remove('active')
    document.getElementById('inprogress').classList.add('inactive')
  }
  // change profilw img
  const changeprofileImage = async (event) => {
    if (profileImage != '') {
      document.getElementById('loadingbar').classList.remove('inactive')
      document.getElementById('loadingbar').classList.add('active')
      document.getElementById('inprogress').classList.remove('active')
      document.getElementById('inprogress').classList.add('inactive')
      document.getElementById('insendclick').classList.add('inactive')
      document.getElementById('insendclick').classList.remove('active')
      const formData = new FormData()
      formData.append('UserID', id)
      formData.append('profileimg', profileImage)
      await fetch('https://api.momoel.com/api/user/edit/photo', {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          console.log('Success:', result)
          if (result.result == 'Done') {
            document.getElementById('indone').classList.toggle('active')
            document.getElementById('indone').classList.toggle('inactive')
            document.getElementById('loadingbar').classList.add('inactive')
            setTimeout(function () {
              document.getElementById('loadingbar').classList.remove('active')
              document.getElementById('loadingbar').classList.add('inactive')
              document.getElementById('indone').classList.toggle('active')
              document.getElementById('indone').classList.toggle('inactive')
              document.getElementById('inprogress').classList.add('active')
              document.getElementById('inprogress').classList.remove('inactive')
            }, 1000)
            window.location.reload(false)
          }
          if (result.result == "We Don't Find New Image!") {
            document.getElementById('loadingbar').classList.add('inactive')
            setTimeout(function () {
              document.getElementById('loadingbar').classList.remove('active')
              document.getElementById('loadingbar').classList.add('inactive')
              document.getElementById('inprogress').classList.add('active')
              document.getElementById('inprogress').classList.remove('inactive')
            }, 1000)
          }
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    }
  }

  return (
    <>
      {localStorage.getItem('userInfo') ? (
        <>
          <motion.div
            className="profile_c"
            initial={{ width: '0' }}
            animate={{ width: '100%' }}
            exit={{ x: window.innerWidth, transaction: { duration: 0.1 } }}
          >
            {localStorage.getItem('Lang') === 'EN' ? (
              <>
                <div className="profile">
                  <div className="profile_d">
                    <div className="profile_s_1">
                      <div className="profilehead"></div>
                      <div className="userprofilphoto">
                        <img
                          src={require('../img/icon-256x256.png')}
                          alt="profilephoto"
                        />
                      </div>
                      <div className="userinfo">
                        <p className="username">
                          {user.FirstName + ' ' + user.LastName}
                        </p>
                      </div>
                      <div className="titleheadprojecttwo">
                        <div
                          className="tablinks active"
                          id="defaultOpen"
                          onClick={openCity.bind(this, 'info')}
                        >
                          My info
                        </div>
                        <div
                          className="tablinks"
                          onClick={openCity.bind(this, 'projects')}
                        >
                          My projects
                        </div>
                        {/* <div
                          className="tablinks"
                          onClick={openCity.bind(this, 'backers')}
                        >
                          {user.GroupId === 1 ? (
                            <>Backers Help Me </>
                          ) : (
                            <>Backers I Help </>
                          )}
                        </div>

                        <div
                          className="tablinks"
                          onClick={openCity.bind(this, 'company')}
                        >
                          Company
                        </div> */}
                        <div
                          className="tablinks"
                          onClick={openCity.bind(this, 'Edit')}
                        >
                          Edit Profile
                        </div>
                      </div>

                      <div className="viewin">
                        <div
                          className="viewdetailssec tabcontent dafultopentab"
                          id="info"
                        >
                          <table>
                            <tbody>
                              <tr>
                                <td>First Name</td>
                                <td>{user.FirstName}</td>
                              </tr>
                              <tr>
                                <td>Last Name</td>
                                <td>{user.LastName}</td>
                              </tr>
                              <tr>
                                <td>User Name</td>
                                <td>{user.UserName}</td>
                              </tr>
                              <tr>
                                <td>E mail</td>
                                <td>{user.email}</td>
                              </tr>
                              <tr>
                                <td>Phone Number</td>
                                <td>{user.Phonenumber}</td>
                              </tr>
                              <tr>
                                <td>Address</td>
                                <td>{user.Address}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div
                          className="viewdetailssec tabcontent  "
                          id="backers"
                        >
                          <div className="backers">
                            <NavLink to="/profile">
                              <div className="backer">
                                <div className="backer_img">
                                  <img
                                    src={require('../img/icon-256x256.png')}
                                    alt="backerimg"
                                  />
                                </div>
                                <div className="backername">Mostafa Briek</div>
                                <div className="backerreng">
                                  Backed this project with $80
                                </div>
                              </div>
                            </NavLink>
                            <NavLink to="/profile">
                              <div className="backer">
                                <div className="backer_img">
                                  <img
                                    src={require('../img/icon-256x256.png')}
                                    alt="backerimg"
                                  />
                                </div>
                                <div className="backername">Mostafa Briek</div>
                                <div className="backerreng">
                                  Backed this project with $80
                                </div>
                              </div>
                            </NavLink>
                            <NavLink to="/profile">
                              <div className="backer">
                                <div className="backer_img">
                                  <img
                                    src={require('../img/icon-256x256.png')}
                                    alt="backerimg"
                                  />
                                </div>
                                <div className="backername">Mostafa Briek</div>
                                <div className="backerreng">
                                  Backed this project with $80
                                </div>
                              </div>
                            </NavLink>
                            <NavLink to="/profile">
                              <div className="backer">
                                <div className="backer_img">
                                  <img
                                    src={require('../img/icon-256x256.png')}
                                    alt="backerimg"
                                  />
                                </div>
                                <div className="backername">Mostafa Briek</div>
                                <div className="backerreng">
                                  Backed this project with $80
                                </div>
                              </div>
                            </NavLink>
                            <NavLink to="/profile">
                              <div className="backer">
                                <div className="backer_img">
                                  <img
                                    src={require('../img/icon-256x256.png')}
                                    alt="backerimg"
                                  />
                                </div>
                                <div className="backername">Mostafa Briek</div>
                                <div className="backerreng">
                                  Backed this project with $80
                                </div>
                              </div>
                            </NavLink>
                          </div>
                        </div>
                        <div
                          className="viewdetailssec tabcontent  "
                          id="company"
                        >
                          Companies
                        </div>
                        <div className="viewdetailssec tabcontent  " id="Edit">
                          Edit
                        </div>
                        <div
                          className="viewdetailssec tabcontent "
                          id="projects"
                        >
                          {projects.length > 0 ? (
                            <>
                              <div className="projects">
                                {projects.length > 0 ? (
                                  <>
                                    {projects?.map((project) => (
                                      <NavLink
                                        to={'/project/' + project.project.id}
                                        key={project.project.id}
                                      >
                                        <div className="project">
                                          <div className="projectimg">
                                            <img
                                              src={
                                                'https://api.momoel.com/storage/projects/' +
                                                project.image.ImageSrc
                                              }
                                              alt="projectimg"
                                            />
                                          </div>
                                          <div className="project_name">
                                            <p className="p_title">
                                              {project.project.ProjectNameAR}
                                            </p>
                                            <p>{project.project.IdeaInfo}</p>
                                            <div className="project_progress">
                                              <span
                                                className="progress_in"
                                                style={{
                                                  width:
                                                    project.projectclc * 100 +
                                                    '%',
                                                }}
                                              ></span>
                                            </div>
                                            <p>
                                              {project.currency.Code}
                                              {project.projectclc} /{' '}
                                              {project.currency.Code}
                                              {project.project.Target}
                                            </p>
                                          </div>
                                        </div>
                                      </NavLink>
                                    ))}
                                  </>
                                ) : (
                                  <>
                                    <div className="Loading">
                                      <div className="Loadingspinner"></div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </>
                          ) : (
                            <>
                              {projects.result == 'NotAvailable' ? (
                                <>
                                  <div className="Loading">Don't have any</div>
                                </>
                              ) : (
                                <>
                                  <div className="Loading">
                                    <div className="Loadingspinner"></div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="profile ar">
                  <div className="profile_d">
                    <div className="profile_s_1">
                      <div className="profilehead"></div>
                      <div className="userprofilphoto">
                        <div className="inimageww">
                          <img
                            id="profileImagepreview"
                            src={
                              'https://api.momoel.com/storage/profile/' +
                              userimg.ImageSrc
                            }
                            alt="profilephoto"
                          />
                        </div>

                        <div className="changeimage">
                          <div id="editimage" className="editprofileimage">
                            <div id="indone" className="indone inactive">
                              <div>
                                <i className="uil uil-check"></i>
                              </div>
                              Done
                            </div>
                            <div id="inprogress" className="active">
                              <i className="uil uil-camera"></i>
                              <input
                                type="file"
                                name="image"
                                value=""
                                onChange={(e) => setimageinprofile(e)}
                              />
                            </div>
                            <div
                              id="insendclick"
                              onClick={changeprofileImage}
                              className="indone inactive"
                            >
                              <div>
                                <i className="uil uil-check"></i>
                              </div>
                              save
                            </div>
                            <div id="loadingbar" className="inactive">
                              <div className="rotateinspanner">
                                <i className="uil uil-spinner"></i>
                              </div>
                              Waiting
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="userinfo">
                        <p className="username">
                          {user.FirstName + ' ' + user.LastName}
                        </p>
                        <p>{userpremetions}</p>
                      </div>
                      <div className="titleheadprojecttwo">
                        <div
                          className="tablinks active"
                          id="defaultOpen"
                          onClick={openCity.bind(this, 'info')}
                        >
                          معلوماتي
                        </div>
                        <div
                          className="tablinks"
                          onClick={openCity.bind(this, 'projects')}
                        >
                          مشاريعي
                        </div>
                        {/* <div
                          className="tablinks"
                          onClick={openCity.bind(this, 'backers')}
                        >
                          {user.GroupId === 7 ? (
                            <>المستثمرون المساعدون </>
                          ) : (
                            <>رواد الاعمال</>
                          )}
                        </div>

                        <div
                          className="tablinks"
                          onClick={openCity.bind(this, 'company')}
                        >
                          الشركة
                        </div> */}
                        <div
                          className="tablinks"
                          onClick={openCity.bind(this, 'Edit')}
                        >
                          تعديل الملف الشخصي
                        </div>
                      </div>

                      <div className="viewin">
                        <div
                          className="viewdetailssec tabcontent dafultopentab"
                          id="info"
                        >
                          <table>
                            <tbody>
                              <tr>
                                <td>الاسم الاول</td>
                                <td>{user.FirstName}</td>
                              </tr>
                              <tr>
                                <td>الاسم الاخير</td>
                                <td>{user.LastName}</td>
                              </tr>
                              <tr>
                                <td>اسم المستخدم</td>
                                <td>{user.UserName}</td>
                              </tr>
                              <tr>
                                <td>البريد الالكتروني</td>
                                <td>{user.email}</td>
                              </tr>
                              <tr>
                                <td>رقم الهاتف</td>
                                <td>{user.Phonenumber}</td>
                              </tr>
                              <tr>
                                <td>العنوان</td>
                                <td>{user.Address}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div
                          className="viewdetailssec tabcontent  "
                          id="backers"
                        >
                          <div className="backers">
                            {/* <NavLink to="/profile">
                              <div className="backer">
                                <div className="backer_img">
                                  <img
                                    src={require('../img/icon-256x256.png')}
                                    alt="backerimg"
                                  />
                                </div>
                                <div className="backername">Mostafa Briek</div>
                                <div className="backerreng">
                                  Backed this project with $80
                                </div>
                              </div>
                            </NavLink>
                    
                            <NavLink to="/profile">
                              <div className="backer">
                                <div className="backer_img">
                                  <img
                                    src={require('../img/icon-256x256.png')}
                                    alt="backerimg"
                                  />
                                </div>
                                <div className="backername">Mostafa Briek</div>
                                <div className="backerreng">
                                  Backed this project with $80
                                </div>
                              </div>
                            </NavLink> */}
                          </div>
                        </div>
                        <div
                          className="viewdetailssec tabcontent  "
                          id="company"
                        >
                          Companies
                        </div>
                        <div className="viewdetailssec tabcontent  " id="Edit">
                          <div className="Editprofile">
                            <table>
                              <tbody>
                                <tr>
                                  <td>الاسم الاول</td>
                                  <td>
                                    <input
                                      type="text"
                                      name="FirstName"
                                      placeholder="الاسم الاول"
                                      value={FirstName}
                                      onChange={(e) =>
                                        setFirstname(e.target.value)
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>الاسم الاخير</td>
                                  <td>
                                    <input
                                      type="text"
                                      name="LastName"
                                      placeholder="الاسم الاول"
                                      value={LastName}
                                      onChange={(e) =>
                                        setLastname(e.target.value)
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>اسم المستخدم</td>
                                  <td>
                                    <input
                                      type="text"
                                      name="UserName"
                                      placeholder="الاسم الاول"
                                      value={user.UserName}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>البريد الالكتروني</td>
                                  <td>
                                    <input
                                      type="text"
                                      name="email"
                                      placeholder="الاسم الاول"
                                      value={user.email}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>رقم الهاتف</td>
                                  <td>
                                    <input
                                      type="text"
                                      name="Phonenumber"
                                      placeholder="الاسم الاول"
                                      value={user.Phonenumber}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>العنوان</td>
                                  <td>
                                    <input
                                      type="text"
                                      name="Address"
                                      placeholder="الاسم الاول"
                                      value={user.Address}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div
                              className="approvedclick"
                              onClick={updateuserinfo}
                            >
                              تعديل بياناتي
                            </div>
                          </div>
                        </div>
                        <div
                          className="viewdetailssec tabcontent "
                          id="projects"
                        >
                          <div className="projects">
                            {projects.length > 0 ? (
                              <>
                                {projects?.map((project) => (
                                  <NavLink
                                    to={'/project/' + project.project.id}
                                    key={project.project.id}
                                  >
                                    <div className="project">
                                      <div className="projectimg">
                                        <img
                                          src={
                                            'https://api.momoel.com/storage/projects/' +
                                            project.image.ImageSrc
                                          }
                                          alt="projectimg"
                                        />
                                      </div>
                                      <div className="project_name">
                                        <p className="p_title">
                                          {project.project.ProjectNameAR}
                                        </p>
                                        <p>{project.project.IdeaInfo}</p>
                                        <div className="project_progress">
                                          <span
                                            className="progress_in"
                                            style={{
                                              width:
                                                project.projectclc * 100 + '%',
                                            }}
                                          ></span>
                                        </div>
                                        <p>
                                          {project.currency.Code}
                                          {project.projectclc} /{' '}
                                          {project.currency.Code}
                                          {project.project.Target}
                                        </p>
                                      </div>
                                    </div>
                                  </NavLink>
                                ))}
                              </>
                            ) : (
                              <>
                                {projects.result == 'NotAvailable' ? (
                                  <>
                                    <div className="Loading">
                                      لا يوجد مشاريع
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="Loading">
                                      <div className="Loadingspinner"></div>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </motion.div>
        </>
      ) : null}
    </>
  )
}
