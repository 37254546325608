import { func } from 'prop-types'
import React, { useEffect } from 'react'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import '../style/navheader.css'
if (localStorage.getItem('userInfo') == 'undefined') {
  localStorage.removeItem('userInfo')
}

function Navbar() {
  let user = JSON.parse(localStorage.getItem('userInfo'))
  const goto = useNavigate()
  function ChangeLang() {
    if (localStorage.getItem('Lang') === 'EN') {
      localStorage.setItem('Lang', 'AR')
    } else {
      localStorage.setItem('Lang', 'EN')
    }
    window.location.reload(false)
  }
  function logout() {
    localStorage.clear()
    window.location.reload(false)
    goto('/')
  }
  const location = useLocation()
  useEffect(() => {
    var mobileminu = document.getElementById('mobileminu')
    var moremen = document.querySelector('.moremen')
    var loginbox = document.querySelector('.loginbox')
    mobileminu.style.display = 'none'
    moremen.style.position = 'relative'
    var x = window.matchMedia('(max-width: 600px)')
    console.log(x)
    if (x.matches) {
      loginbox.style.display = 'none'
    }
  }, [location])

  function clickinmoremob() {
    var mobileminu = document.getElementById('mobileminu')
    var moremen = document.querySelector('.moremen')
    var loginbox = document.querySelector('.loginbox')

    if (mobileminu.style.display === 'flex') {
      mobileminu.style.display = 'none'
      moremen.style.position = 'relative'
    } else {
      mobileminu.style.display = 'flex'
      moremen.style.position = 'fixed'
    }
    if (loginbox.style.display === 'flex') {
      loginbox.style.display = 'none'
      moremen.style.position = 'relative'
    }
  }
  function openloginbox() {
    var openloginbox = document.getElementById('openloginbox')
    var loginbox = document.querySelector('.loginbox')
    var moremen = document.querySelector('.moremen')
    var close = document.querySelector('.close')
    if (loginbox.style.display === 'flex') {
      loginbox.style.display = 'none'
      moremen.style.position = 'relative'
    } else {
      loginbox.style.display = 'flex'
      moremen.style.position = 'fixed'
    }
  }
  function closeloginbox() {
    var openloginbox = document.getElementById('openloginbox')
    var loginbox = document.querySelector('.loginbox')
    var moremen = document.querySelector('.moremen')
    var close = document.querySelector('.close')
    if (loginbox.style.display === 'flex') {
      loginbox.style.display = 'none'
      moremen.style.position = 'relative'
    } else {
      loginbox.style.display = 'flex'
      moremen.style.position = 'fixed'
    }
  }
  return (
    <>
      {localStorage.getItem('Lang') === 'EN' ? (
        <>
          <div className="navbar navin" id="navbar">
            <div className="navbar_D ">
              <div className="klright">
                <i
                  className="uil uil-align-justify moremen"
                  onClick={clickinmoremob}
                ></i>
                <div className="logo">
                  <NavLink to="/">
                    <span className="logo_D"></span>
                  </NavLink>
                </div>
                <div className="mobileminu" id="mobileminu">
                  <div className="mobmeniincenterdiv">
                    <NavLink to="/explore"> Explore </NavLink>
                    <NavLink to="/howitworks"> How It Works </NavLink>
                    <NavLink to="/start"> Start Your Project </NavLink>
                    {localStorage.getItem('userInfo') ? (
                      user.GroupId <= 5 ? (
                        <>
                          <NavLink to="/crm"> crm </NavLink>
                        </>
                      ) : null
                    ) : null}
                  </div>
                </div>
                <div className="minimenu">
                  <NavLink to="/explore"> Explore </NavLink>|
                  <NavLink to="/howitworks"> How It Works </NavLink>|
                  <NavLink to="/start"> Start Your Project </NavLink>
                  {localStorage.getItem('userInfo') ? (
                    user.GroupId <= 5 ? (
                      <>
                        | <NavLink to="/crm"> crm </NavLink>
                      </>
                    ) : null
                  ) : null}
                </div>
              </div>
              <div className="klright">
                <div className="login_D">
                  {!localStorage.getItem('userInfo') ? (
                    <>
                      <div className="loginbox">
                        <i
                          className="uil uil-multiply close"
                          onClick={closeloginbox}
                        ></i>
                        <div className="incenterbox">
                          You Have account ?
                          <NavLink to="/signin">Sign in </NavLink>
                          <NavLink to="/Signup"> Signup</NavLink>
                        </div>
                      </div>

                      <i
                        className="usericon uil uil-user-circle"
                        onClick={openloginbox}
                      ></i>
                    </>
                  ) : (
                    <>
                      <div className="loginbox">
                        <i
                          className="uil uil-multiply close enright"
                          onClick={closeloginbox}
                        ></i>
                        <div className="incenterbox">
                          <NavLink to="/profile">
                            {user.FirstName + ' ' + user.LastName}
                          </NavLink>
                          <div className="logout" onClick={logout}>
                            logout
                          </div>
                        </div>
                      </div>
                      <i
                        className="usericon uil uil-user-circle"
                        onClick={openloginbox}
                      ></i>
                    </>
                  )}
                </div>
                <div className="language_">
                  <span className="btn1 lang" onClick={ChangeLang}>
                    {localStorage.getItem('Lang') === 'EN' ? (
                      <>العربية</>
                    ) : (
                      <>English</>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="navbar navin ar" id="navbar">
            <div className="navbar_D ">
              <div className="klright">
                <i
                  className="uil uil-align-justify moremen"
                  onClick={clickinmoremob}
                ></i>
                <div className="logo">
                  <NavLink to="/">
                    <span className="logo_D"></span>
                  </NavLink>
                </div>
                <div className="mobileminu" id="mobileminu">
                  <div className="mobmeniincenterdiv">
                    <NavLink to="/explore"> اكتشف اكثر </NavLink>
                    <NavLink to="/howitworks"> كيف نعمل </NavLink>
                    <NavLink to="/start"> ابدأ مشروعك </NavLink>
                    {localStorage.getItem('userInfo') ? (
                      user.GroupId <= 5 ? (
                        <>
                          <NavLink to="/crm"> لوحة التحكم </NavLink>
                        </>
                      ) : null
                    ) : null}
                  </div>
                </div>
                <div className="minimenu">
                  <NavLink to="/explore"> اكتشف اكثر </NavLink>|
                  <NavLink to="/howitworks"> كيف نعمل </NavLink>|
                  <NavLink to="/start"> ابدأ مشروعك </NavLink>
                  {localStorage.getItem('userInfo') ? (
                    user.GroupId <= 5 ? (
                      <>
                        | <NavLink to="/crm"> لوحة التحكم </NavLink>
                      </>
                    ) : null
                  ) : null}
                </div>
              </div>
              <div className="klright">
                <div className="login_D">
                  {!localStorage.getItem('userInfo') ? (
                    <>
                      <div className="loginbox">
                        <i
                          className="uil uil-multiply close"
                          onClick={closeloginbox}
                        ></i>
                        <div className="incenterbox">
                          <div>هل لديك حساب ؟</div>
                          <NavLink to="/signin">تسجيل دخول </NavLink>
                          <NavLink to="/Signup"> نسجيل</NavLink>
                        </div>
                      </div>
                      <i
                        className="usericon uil uil-user-circle"
                        id="openloginbox"
                        onClick={openloginbox}
                      ></i>
                    </>
                  ) : (
                    <>
                      <div className="loginbox">
                        <i
                          className="uil uil-multiply close"
                          onClick={closeloginbox}
                        ></i>
                        <div className="incenterbox">
                          <NavLink to="/profile">
                            {user.FirstName + ' ' + user.LastName}
                          </NavLink>
                          <div className="logout" onClick={logout}>
                            تسجيل خروج
                          </div>
                        </div>
                      </div>
                      <i
                        className="usericon uil uil-user-circle"
                        id="openloginbox"
                        onClick={openloginbox}
                      ></i>
                    </>
                  )}
                </div>
                <div className="language_">
                  <span className="btn1 lang" onClick={ChangeLang}>
                    {localStorage.getItem('Lang') === 'EN' ? (
                      <>العربية</>
                    ) : (
                      <>English</>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Navbar
