import { React, useEffect, useState } from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
// import Box from '@mui/material/Box'
// import { DataGrid } from '@mui/x-data-grid'

// import DataGrid from 'react-data-grid'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

const gotoproject = (p, { refreshcell }) => {
  var projectid = p.data.project.id
  let user = JSON.parse(localStorage.getItem('userInfo'))
  var adminid = user.id
  console.log(p)

  function getProjectid() {
    var options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Origin: '',
        Connection: 'keep-alive',
      },
      body: JSON.stringify({
        grant_type: 'client_credentials',
        AdminID: adminid,
      }),
    }
    fetch(
      'https://api.momoel.com/api/crm/project/approved/' + projectid,
      options,
    )
      .then(function (res) {
        return res.json()
      })
      .then(function (resJson) {
        return resJson
      })
  }

  async function deleteproject() {
    var options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Origin: '',
        Connection: 'keep-alive',
      },
      body: JSON.stringify({
        grant_type: 'client_credentials',
        AdminID: adminid,
      }),
    }
    await fetch(
      'https://api.momoel.com/api/crm/project/delete/' + projectid,
      options,
    )
      .then(function (res) {
        return res.json()
      })
      .then(function (resJson) {
        return resJson
      })
    p.api.refreshCells()
    console.log(p.api)
  }
  return (
    <>
      <button className="buttoninview" onClick={getProjectid}>
        عرض
      </button>
      <button className="buttoninedit" onClick={getProjectid}>
        موافقه
      </button>
      <button className="buttonindelete" onClick={deleteproject}>
        رفض
      </button>
    </>
  )
}
const gettime = (p) => {
  var date = new Date(p.data.project.CrateDateTime)
  var year = date.toLocaleString('default', { year: 'numeric' })
  var month = date.toLocaleString('default', { month: '2-digit' })
  var day = date.toLocaleString('default', { day: '2-digit' })
  var hour = date.toLocaleString('default', { h: '2-digit' })
  var minute = date.toLocaleString('default', { minute: '2-digit' })

  var formattedDate = day + '-' + month + '-' + year
  return hour
}
const gettimeedited = (p) => {
  var date = new Date(p.data.project.UpdateDateTime)
  var year = date.toLocaleString('default', { year: 'numeric' })
  var month = date.toLocaleString('default', { month: '2-digit' })
  var day = date.toLocaleString('default', { day: '2-digit' })
  var hour = date.toLocaleString('default', { h: '2-digit' })
  var minute = date.toLocaleString('default', { minute: '2-digit' })

  var formattedDate =
    day + '-' + month + '-' + year + ' | ' + hour + ' : ' + minute + ' '
  return hour
}

export default function DataGridDemo(refreshcell) {
  const [projects, getProjects] = useState([])
  const rowData = projects

  async function getProjectsfun() {
    let result = await fetch('https://api.momoel.com/api/crm/project/view/all')
    result = await result.json()
    getProjects(result)
  }
  useEffect(() => {
    getProjectsfun()
  }, [])

  const columnDefs = [
    {
      field: 'project.id',
      headerName: 'رقم المشروع',
      filter: true,
      maxWidth: 200,
      sortable: true,
    },
    // Using dot notation to access nested property
    {
      field: 'project.ProjectNameAR',
      headerName: 'اسم المشروع',
      filter: 'agTextColumnFilter',
      sortable: true,
    },
    {
      field: 'project.ProjectName',
      headerName: 'EN اسم المشروع',
      filter: 'agTextColumnFilter',
      sortable: true,
    },
    {
      field: 'project.Target',
      headerName: 'الهدف',
      maxWidth: 100,
      sortable: true,
    },
    {
      field: 'currency.Code',
      headerName: ' العمله',
      maxWidth: 100,
      sortable: true,
    },
    {
      field: 'project.CrateDateTime',
      headerName: ' تاريح الانشاء',
      sortable: true,
      cellRenderer: gettime,
    },
    {
      field: 'currency.Code',
      headerName: ' اخر نعديل',
      sortable: true,
      cellRenderer: gettimeedited,
    },
    {
      field: 'project',
      headerName: ' ',
      cellRenderer: gotoproject,
      minWidth: 300,
    },
  ]
  function refreshcell() {
    getProjectsfun()
  }
  // var t = setInterval(refreshcell, 3000)
  const getRowId = (params) => params.data.id

  return (
    <>
      <motion.div
        className="CRMPAGE" //layoutId="underline"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="pagetitle"> مراجعة المشاريع</div>
        <div className="refreshcell" onClick={refreshcell}>
          refresh
        </div>
        {projects ? (
          <>
            <div>
              <div style={{ height: '90vh' }} className="ag-theme-alpine">
                {/* <DataGrid columns={columns} rowHeight={rowHeight} rows={rows} /> */}
                <AgGridReact
                  style={{ width: '100%', height: '100%;' }}
                  rowData={rowData}
                  enableRtl={true}
                  columnDefs={columnDefs}
                  animateRows={true}
                  rowSelection={'multiple'}
                  // getRowId={getRowId}
                ></AgGridReact>
              </div>
            </div>
          </>
        ) : (
          <>
            {/* <DataGrid columns={columns} rowHeight={rowHeight} rows={rows} /> */}
            <div>
              <div style={{ height: '90vh' }} className="ag-theme-alpine">
                {/* <DataGrid columns={columns} rowHeight={rowHeight} rows={rows} /> */}
                <AgGridReact
                  style={{ width: '100%', height: '100%;' }}
                  rowData={rowData}
                  enableRtl={true}
                  columnDefs={columnDefs}
                  animateRows={true}
                  rowSelection={'multiple'}
                  // getRowId={getRowId}
                ></AgGridReact>
              </div>
            </div>
          </>
        )}
      </motion.div>
    </>
  )
}
