import React, { useState, useEffect } from 'react'
import {
  createRoutesFromChildren,
  NavLink,
  useNavigate,
} from 'react-router-dom'
import { motion } from 'framer-motion'

export default function Profile() {
  return (
    <>
      <motion.div
        className="CRMPAGE"
        initial={{ width: '0%' }}
        animate={{ width: '100%' }}
        exit={{ x: window.innerWidth, transaction: { duration: 0.1 } }}
      >
        <div className="pagetitle">Home</div>
      </motion.div>
    </>
  )
}
