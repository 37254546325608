import { React, useEffect, useState } from 'react'
import {
  createRoutesFromChildren,
  NavLink,
  useNavigate,
} from 'react-router-dom'
import { motion } from 'framer-motion'

import '../style/sign.css'
import '../style/forms.css'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

export default function Addnewcompany() {
  const goto = useNavigate()
  let user = JSON.parse(localStorage.getItem('userInfo'))

  useEffect(() => {
    if (!localStorage.getItem('userInfo')) {
      goto('/signin')
    } else {
      let user = JSON.parse(localStorage.getItem('userInfo'))
    }
  }, [])

  // form validation
  const [CompanyNameEN, setCompanyNameEN] = useState('')
  const [CompanyNameAR, setCompanyNameAR] = useState('')
  const [Email, setEmail] = useState('')
  const [PhoneNumber, setPhoneNumber] = useState('')
  const [Address, setAddress] = useState('')
  const [CountryID, setCountryID] = useState('1')
  const [CoverImageID, setCoverImageID] = useState('1')
  const [LogoImageID, setLogoImageID] = useState('1')
  const [UserID, setUserID] = useState(user?.id)

  async function addcompany() {
    let company = {
      CompanyNameEN,
      CompanyNameAR,
      Email,
      PhoneNumber,
      Address,
      CountryID,
      CoverImageID,
      LogoImageID,
      UserID,
    }
    let url = 'https://api.momoel.com/api/company/add'
    let result = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(company),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    result = await result.json()
    if (result.state === 'success') {
      goto('/company/' + result.companyID)
    }
  }

  let value = ''
  let setValue = ''
  let modules = {
    toolbar: [
      [{ font: [] }],
      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction
      [{ align: [] }],

      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript

      ['link', 'image', 'video', 'formula'], // add's image support
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme

      ['clean'], // remove formatting button
    ],
  }

  let formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ]
  return (
    <>
      <motion.div
        className="addnewproject" //layoutId="underline"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        {localStorage.getItem('Lang') === 'EN' ? (
          <>
            <div className="newproject">
              <div className="alert">
                Hello, {user.FirstName} You are just steps away from adding your
                new Company
              </div>
              <div className="newprojectForm">
                <input
                  type="text"
                  name="CompanyNameAR"
                  placeholder="Company Name By Arabic"
                />
                <input
                  type="text"
                  name="CompanyNameEN"
                  placeholder="Company Name By English"
                />
                <input type="text" name="Email" placeholder="Email" />
                <input
                  type="text"
                  name="PhoneNumber"
                  placeholder="Phone Number"
                />
                <input type="text" name="Address" placeholder="Address" />

                {/* <div className="detailsform">
                  <ReactQuill
                    theme="snow"
                    value={value}
                    onChange={setValue}
                    modules={modules}
                    formats={formats}
                  />
                </div> */}
                <div className="newprojectsubmit">Add Your Company</div>
              </div>
            </div>
          </>
        ) : (
          <>
            {localStorage.getItem('userInfo') ? (
              <div className="newproject ar">
                <div className="alert">
                  اهلا, {user.FirstName} انت علي بعد خطوات من اضافة شركتك
                </div>
                <div className="newprojectForm">
                  <input
                    type="text"
                    name="CompanyNameAR"
                    placeholder="اسم الشركه بالعربيه"
                    onChange={(e) => setCompanyNameAR(e.target.value)}
                  />
                  <input
                    type="text"
                    name="CompanyNameEN"
                    placeholder="اسم الشركه بالانجليزيه"
                    onChange={(e) => setCompanyNameEN(e.target.value)}
                  />
                  <input
                    type="text"
                    name="Email"
                    placeholder="البريد الالكتروني"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <input
                    type="text"
                    name="PhoneNumber"
                    placeholder="رقم الهاتف"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                  <input
                    type="text"
                    name="Address"
                    placeholder="العنوان"
                    onChange={(e) => setAddress(e.target.value)}
                  />
                  <div className="newprojectsubmit" onClick={addcompany}>
                    اضف شركتك
                  </div>
                </div>
              </div>
            ) : null}
          </>
        )}
      </motion.div>
    </>
  )
}
