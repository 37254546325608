import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import '../style/sign.css'
import { motion } from 'framer-motion'

export default function Signin() {
  const goto = useNavigate()
  useEffect(() => {
    if (localStorage.getItem('userInfo')) {
      goto('/')
    }
  }, [])

  const [email, Getusername] = useState('')
  const [password, Getuserpassword] = useState('')

  async function login() {
    let user = { email, password }
    let url = 'https://api.momoel.com/api/signin'
    let result = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(user),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    result = await result.json()
    // console.warn(result)
    if (result.code === 20) {
      localStorage.setItem('userInfo', JSON.stringify(result.user))
      goto('/')
    }
    if (result.code === 401) {
      // console.log('errorlogin')
    }
  }
  return (
    <>
      <motion.div
        className="signin_c control_c" //layoutId="underline"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="login_crm">
          <span className="loplock">
            <i className="uil uil-padlock"></i>
          </span>
          <NavLink to="/">
            <span className="logo_D_crm"></span>
          </NavLink>
          <div className="headername">
            <p>control panal</p>
            <h1>Login</h1>
          </div>
          <div className="boxin">
            <div className="boxinput_">
              <input
                type="text"
                name="username"
                placeholder="username or email"
                onChange={(e) => Getusername(e.target.value)}
              />
              <i className="uil uil-user-square"></i>
            </div>
            <div className="boxinput_">
              <input
                type="password"
                name="userpassword"
                placeholder="Password"
                onChange={(e) => Getuserpassword(e.target.value)}
              />
              <i className="uil uil-lock"></i>
            </div>
            <div className="boxinput_ signinsubmit">
              <input
                type="submit"
                name="submit"
                value="signin"
                onClick={login}
                onKeyDown={login}
              />
              <i className="uil uil-signin"></i>
            </div>
          </div>
          <div className="boxinput__">
            <input type="checkbox" name="remember"></input>
            <label for="remember">Remember Me</label>
          </div>
          <div className="boxch">
            <NavLink to="/">Go To Main Website</NavLink>
          </div>
          {/* <MDBBtn>Button</MDBBtn> */}
        </div>
      </motion.div>
    </>
  )
}
