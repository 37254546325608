import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { motion } from 'framer-motion'
export default class explore extends Component {
  render() {
    return (
      <>
        <motion.div
          className="explore_d_page"
          //layoutId="underline"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          {localStorage.getItem('Lang') === 'EN' ? (
            <>
              <div className="explore">
                <div className="explore_cover">
                  <img
                    src={require('../img/1660s049823348733680.jpg')}
                    alt="explore"
                  />
                  <p>How it Works</p>
                </div>
                <NavLink to="/newcompany">
                  <div className="Sectioninexplore">
                    <i className="uil uil-building"></i>Add Your Company
                  </div>
                </NavLink>
                <NavLink to="/newproject">
                  <div className="Sectioninexplore">
                    <i className="uil uil-clapper-board"></i>Add Your Project
                  </div>
                </NavLink>
                <NavLink to="/explore">
                  <div className="Sectioninexplore">
                    <i className="uil uil-eye"></i>View Projects
                  </div>
                </NavLink>
                {/* <NavLink to="/projectsupportrequest">
                <div className="Sectioninexplore">
                  <i className="uil uil-life-ring"></i>Project Support Request
                </div>
              </NavLink> */}
              </div>
            </>
          ) : (
            <>
              <div className="explore">
                <div className="explore_cover">
                  <img
                    src={require('../img/1660s049823348733680.jpg')}
                    alt="explore"
                  />
                  <p>كيف تبداء العمل</p>
                </div>
              </div>
              <NavLink to="/newcompany">
                <div className="Sectioninexplore ar">
                  <i className="uil uil-building"></i>اضف شركتك
                </div>
              </NavLink>
              <NavLink to="/newproject">
                <div className="Sectioninexplore ar">
                  <i className="uil uil-clapper-board"></i>اضف مشروعك
                </div>
              </NavLink>
              <NavLink to="/explore">
                <div className="Sectioninexplore ar">
                  <i className="uil uil-eye"></i>مشاهدة المشاريع
                </div>
              </NavLink>
              {/* <NavLink to="/projectsupportrequest">
                <div className="Sectioninexplore ar">
                  <i className="uil uil-life-ring"></i>طلب دعم مشروع
                </div>
              </NavLink> */}
            </>
          )}
        </motion.div>
      </>
    )
  }
}
