import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { motion } from 'framer-motion'
import '../style/start.css'
export default class explore extends Component {
  render() {
    return (
      <>
        <motion.div
          className="explore_d_page" //layoutId="underline"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          {localStorage.getItem('Lang') === 'EN' ? (
            <>
              <div className="explore">
                <div className="explore_cover">
                  <img
                    src={require('../img/1660s049823348733680.jpg')}
                    alt="explore"
                  />
                  <p>Start Your Project</p>
                </div>
                <div className="startsteps">
                  <div className="step">
                    <p className="num">1</p>
                    <div className="det">
                      Login By Your Email Or Start Rejester
                    </div>
                  </div>
                  <div className="step">
                    <p className="num">2</p>
                    <div className="det">Add Your Company Information</div>
                  </div>
                  <div className="step">
                    <p className="num">3</p>
                    <div className="det">
                      Start Add Your Project And Wait untill Mmol Review it
                    </div>
                  </div>
                  <div className="step">
                    <p className="num">4</p>
                    <div className="det">
                      Wait until investors see your project and support it with
                      money
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="explore">
                <div className="explore_cover">
                  <img
                    src={require('../img/1660s049823348733680.jpg')}
                    alt="explore"
                  />
                  <p>ابدأ مشروعك الان</p>
                </div>
                <div className="startsteps ar">
                  <div className="step">
                    <p className="num">1</p>
                    <div className="det">
                      سجل دخولك الان او ابدأ بتسجيل بياناتك
                    </div>
                  </div>
                  <div className="step">
                    <p className="num">2</p>
                    <div className="det">اضف بيانات شركتك</div>
                  </div>
                  <div className="step">
                    <p className="num">3</p>
                    <div className="det">
                      ابدأ في اضافة مشروعك و انتظر حتي يقوم فريق ممول بمراجعته
                    </div>
                  </div>
                  <div className="step">
                    <p className="num">4</p>
                    <div className="det">
                      انتظر حتي يري المستثمرون مشروعك و دعمه بالاموال
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </motion.div>
      </>
    )
  }
}
