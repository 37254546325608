import './style/App.css'
import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
import Navbar from './components/navbar'
import Header from './components/header'
import { Pageroute } from './components/pageroute'

function App() {
  useEffect(() => {
    document.title = 'Mmol Home'
  })
  return (
    <>
      <BrowserRouter>
        <Pageroute />
      </BrowserRouter>
    </>
  )
}

export default App
