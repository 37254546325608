import { React, useEffect, useState } from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import '../style/project.css'
import { motion } from 'framer-motion'
// import react quill
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
export default function Project() {
  const location = useLocation()
  const { projectid } = useParams()
  const [project, getProject] = useState([])
  const [coverImage, changeCoverImageval] = useState([])

  const setimageincover = (event) => {
    document.getElementById('coverImagepreview').src = URL.createObjectURL(
      event.target.files[0],
    )
    changeCoverImageval(event.target.files[0])
    document.getElementById('insendclick').classList.remove('inactive')
    document.getElementById('insendclick').classList.add('active')
    document.getElementById('inprogress').classList.remove('active')
    document.getElementById('inprogress').classList.add('inactive')
  }

  // change cover img
  const changeCoverImage = async (event) => {
    if (coverImage != '') {
      document.getElementById('loadingbar').classList.remove('inactive')
      document.getElementById('loadingbar').classList.add('active')
      document.getElementById('inprogress').classList.remove('active')
      document.getElementById('inprogress').classList.add('inactive')
      document.getElementById('insendclick').classList.add('inactive')
      document.getElementById('insendclick').classList.remove('active')
      const formData = new FormData()
      formData.append('UserID', UserID)
      formData.append('ProjectId', projectid)
      formData.append('projectimg', coverImage)
      await fetch('https://api.momoel.com/api/project/uploadphoto', {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          console.log('Success:', result)
          if (result.result == 'Done') {
            document.getElementById('indone').classList.toggle('active')
            document.getElementById('indone').classList.toggle('inactive')
            document.getElementById('loadingbar').classList.add('inactive')
            setTimeout(function () {
              document.getElementById('loadingbar').classList.remove('active')
              document.getElementById('loadingbar').classList.add('inactive')
              document.getElementById('indone').classList.toggle('active')
              document.getElementById('indone').classList.toggle('inactive')
              document.getElementById('inprogress').classList.add('active')
              document.getElementById('inprogress').classList.remove('inactive')
            }, 1000)
            window.location.reload(false)
          }
          if (result.result == "We Don't Find New Image!") {
            document.getElementById('loadingbar').classList.add('inactive')
            setTimeout(function () {
              document.getElementById('loadingbar').classList.remove('active')
              document.getElementById('loadingbar').classList.add('inactive')
              document.getElementById('inprogress').classList.add('active')
              document.getElementById('inprogress').classList.remove('inactive')
            }, 1000)
          }
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    }
  }
  // end change cover img

  let user = JSON.parse(localStorage.getItem('userInfo'))
  // quill options
  let value = ''
  let setValue = ''
  let modules = {
    toolbar: [
      [{ font: [] }],
      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction
      [{ align: [] }],

      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript

      ['link', 'image', 'video', 'formula'], // add's image support
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme

      ['clean'], // remove formatting button
    ],
  }

  let formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ]
  // end of quill options

  useEffect(() => {
    async function getProjectfun() {
      let result = await fetch(
        'https://api.momoel.com/api/project/view/' + projectid,
      )
      result = await result.json()
      getProject(result)
    }
    getProjectfun()
    // async function getcompaniesfun() {
    //   let result = await fetch(
    //     'https://api.momoel.com/api/user/company/' + user.id,
    //   )
    //   result = await result.json()

    //   getcompanies(result)
    // }
    // getcompaniesfun()
  }, [])

  const [projects, getProjects] = useState([])
  useEffect(() => {
    async function getProjectsfun() {
      let result = await fetch(
        'https://api.momoel.com/api/project/view/projects/4',
      )
      result = await result.json()

      getProjects(result)
    }
    getProjectsfun()
  }, [])

  // projects view in English
  const projectsview = projects.map((project) => (
    <NavLink to={'/project/' + project.project.id} key={project.project.id}>
      <div className="project">
        <div className="projectimg">
          <img
            src={
              'https://api.momoel.com/storage/projects/' +
              project.image.ImageSrc
            }
            alt="projectimg"
          />
        </div>
        <div className="project_name">
          <p className="p_title">{project.project.ProjectName}</p>
          <p>{project.project.IdeaInfo}</p>
          <div className="project_progress">
            <span
              className="progress_in"
              style={{
                width: project.projectclc * 100 + '%',
              }}
            ></span>
          </div>
          <p>
            {project.currency.Code}
            {project.projectclc} / {project.currency.Code}
            {project.project.Target}
          </p>
        </div>
      </div>
    </NavLink>
  ))
  // projects view in Arabic

  const projectsviewAR = projects.map((project) => (
    <NavLink to={'/project/' + project.project.id} key={project.project.id}>
      <div className="project">
        <div className="projectimg">
          <img
            src={
              'https://api.momoel.com/storage/projects/' +
              project.image.ImageSrc
            }
            alt="projectimg"
          />
        </div>
        <div className="project_name">
          <p className="p_title">{project.project.ProjectNameAR}</p>
          <p>{project.project.IdeaInfo}</p>
          <div className="project_progress">
            <span
              className="progress_in"
              style={{
                width: project.projectclc * 100 + '%',
              }}
            ></span>
          </div>
          <p>
            {project.currency.Code}
            {project.projectclc} / {project.currency.Code}
            {project.project.Target}
          </p>
        </div>
      </div>
    </NavLink>
  ))

  // const companiespro = companies.map((company) => (
  //   <option value={company.id}>{company.CompanyNameAR}</option>
  // ))
  // according
  const openCity = (cityName, event) => {
    var i, tabcontent, tablinks
    tabcontent = document.getElementsByClassName('tabcontent')
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none'
    }
    tablinks = document.getElementsByClassName('tablinks')
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '')
    }
    if (document.getElementById(cityName)) {
      const box = document.getElementById(cityName)
      box.style.display = 'block'
    }
    // console.log('this is:', event)
    event.currentTarget.className += ' active'
  }

  // console.log(project)
  // edit project content
  // form validation
  // console.log(project.project)
  // console.log()
  // const [projectfg] = useState(Project.project)
  // const [projectinedit] = useState(project.project)
  // console.log(project.project.ProjectName)
  const [ProjectName, editProjectName] = useState('')
  const [ProjectNameAR, editProjectNameAR] = useState('fff')
  const [ProjectDetails, editProjectDetails] = useState('')
  const [IdeaInfo, editIdeaInfo] = useState('')
  const [Target, editTarget] = useState('')
  const [CurrencyId, editCurrencyId] = useState('')
  const [CompanyID, editCompanyID] = useState('')
  const [UserID, editUserID] = useState(user?.id)

  async function editproject() {
    let project = {
      ProjectName,
      ProjectNameAR,
      ProjectDetails,
      IdeaInfo,
      Target,
      CurrencyId,
      CompanyID,
      UserID,
    }
    let url = 'https://api.momoel.com/api/project/add'
    let result = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(project),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    result = await result.json()
    console.warn('result', result)
  }
  const [finance, setfinance] = useState(0)
  async function supportrequest() {
    let ProjectId = project.project.id
    let projectsubbort = {
      ProjectId,
      finance,
      UserID,
    }
    let url = 'https://api.momoel.com/api/project/requestfinance'
    let result = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(projectsubbort),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    result = await result.json()
    document.getElementById('yourfinince').innerHTML =
      ' تم ارسال طلب الدعم بنجاح'
  }
  return (
    <>
      <motion.div
        className="explore_d_page" //layoutId="underline"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        {project && project.result === 'Available' ? (
          <>
            {localStorage.getItem('Lang') === 'EN' ? (
              <>
                <div className="explore">
                  <div className="project_cover">
                    <div className="blur">
                      <p>
                        <i className="uil uil-shield-exclamation"></i>
                        {project.project.ProjectName}
                      </p>
                    </div>
                    <img
                      src={require('../img/Project-BG-2005.jpg')}
                      alt="explore"
                    />
                    {localStorage.getItem('userInfo') &&
                    project.project.UserID == user.id ? (
                      <>
                        <div id="editimage" className="editimage">
                          Edit Image
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="project_page">
                    <div className="projectcompanysection">
                      {project.project.CompanyID == 0 ? (
                        <>
                          <div className="projectcompany">
                            <div className="companyimg">
                              <img
                                src={require('../img/icon-256x256.png')}
                                alt="profileimg"
                              />
                            </div>
                            <div className="companydetails">
                              <p className="companyname">
                                {project.projectowner.FirstName +
                                  ' ' +
                                  project.projectowner.LastName}
                              </p>
                              <p className="companydetails">
                                {project.projectowner.email}
                              </p>
                              <p className="companyplace">
                                <i className="uil uil-map-marker-shield"></i>
                                Egypt
                              </p>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <NavLink to={'/company/' + project.project.CompanyID}>
                            <div className="projectcompany">
                              <div className="companyimg">
                                <img
                                  src={require('../img/company.jpg')}
                                  alt="companyimg"
                                />
                              </div>
                              <div className="companydetails">
                                <p className="companyname">
                                  {project.company.CompanyNameEN}
                                </p>
                                <p className="companydetails">
                                  {project.company.Email}
                                </p>
                                <p className="companyplace">
                                  <i className="uil uil-map-marker-shield"></i>
                                  Egypt
                                </p>
                              </div>
                            </div>
                          </NavLink>
                        </>
                      )}
                      <div className="progressbarsec">
                        <p>
                          <i className="uil uil-shield-exclamation"></i>
                          {project.project.ProjectName}
                        </p>
                        <div className="progress_bar">
                          <span
                            className="progress_in"
                            style={{
                              width:
                                (project.projectcalc / project.project.Target) *
                                  100 +
                                '%',
                            }}
                          ></span>
                        </div>
                        <p>
                          {project.currency.Code +
                            ' ' +
                            project.projectcalc +
                            ' / ' +
                            project.currency.Code +
                            ' ' +
                            project.project.Target}
                        </p>
                      </div>
                    </div>
                    <div className="titleheadprojecttwo">
                      <div
                        className="tablinks active"
                        id="defaultOpen"
                        onClick={openCity.bind(this, 'details')}
                      >
                        Details
                      </div>
                      <div
                        className="tablinks"
                        onClick={openCity.bind(this, 'backers')}
                      >
                        Backers
                      </div>
                    </div>
                    <div
                      className="viewdetailssec tabcontent dafultopentab"
                      id="details"
                      dangerouslySetInnerHTML={{
                        __html: project.project.ProjectDetails,
                      }}
                    ></div>
                    <div className="viewdetailssec tabcontent" id="backers">
                      <div className="backers">
                        {project.projectfinance.map((pfinance, index) => (
                          <div className="backer" key={index}>
                            <div className="backer_img">
                              <img
                                src={require('../img/icon-256x256.png')}
                                alt="backerimg"
                              />
                            </div>
                            <div className="backername">
                              {pfinance.FirstName + ' ' + pfinance.LastName}
                            </div>
                            <div className="backerreng">
                              {'Backed this project with' +
                                pfinance.finance +
                                ' ' +
                                project.currency.Code}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="titleheadproject">
                      More Projects You can see it
                    </div>
                    {projects.length > 0 ? (
                      <>
                        <div className="projects">{projectsview}</div>
                      </>
                    ) : (
                      <>
                        <div className="Loading">
                          <div className="Loadingspinner"></div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="explore ar">
                  <div className="project_cover">
                    <div className="blur">
                      <p>
                        <i className="uil uil-shield-exclamation"></i>
                        {project.project.ProjectNameAR}
                      </p>
                    </div>
                    <img
                      id="coverImagepreview"
                      src={
                        'https://api.momoel.com/storage/projects/' +
                        project.covserimg.ImageSrc
                      }
                      alt="explore"
                    />
                    {localStorage.getItem('userInfo') &&
                    project.project.UserID == user.id ? (
                      <>
                        <div
                          id="editimage"
                          className="editimage"
                          // onChange={}
                        >
                          <div id="indone" className="indone inactive">
                            <div>
                              <i className="uil uil-check"></i>
                            </div>
                            تم التعديل بنجاح
                          </div>
                          <div id="inprogress" className="active">
                            <i className="uil uil-camera"></i> نعديل صورة الغلاف
                            <input
                              type="file"
                              name="image"
                              value=""
                              onChange={(e) => setimageincover(e)}
                            />
                          </div>
                          <div
                            id="insendclick"
                            onClick={changeCoverImage}
                            className="indone inactive"
                          >
                            <div>
                              <i className="uil uil-check"></i>
                            </div>
                            حفظ
                          </div>
                          <div id="loadingbar" className="inactive">
                            <div className="rotateinspanner">
                              <i className="uil uil-spinner"></i>
                            </div>
                            جاري التحميل
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="project_page">
                    <div className="projectcompanysection">
                      {project.project.CompanyID == 0 ? (
                        <>
                          <div className="projectcompany">
                            <div className="companyimg">
                              <img
                                src={require('../img/icon-256x256.png')}
                                alt="profileimg"
                              />
                            </div>
                            <div className="companydetails">
                              <p className="companyname">
                                {project.projectowner.FirstName +
                                  ' ' +
                                  project.projectowner.LastName}
                              </p>
                              <p className="companydetails">
                                {project.projectowner.email}
                              </p>
                              <p className="companyplace">
                                <i className="uil uil-map-marker-shield"></i>
                                {project.projectcountery.NameAr}
                              </p>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <NavLink to={'/company/' + project.project.CompanyID}>
                            <div className="projectcompany">
                              <div className="companyimg">
                                <img
                                  src={require('../img/company.jpg')}
                                  alt="companyimg"
                                />
                              </div>
                              <div className="companydetails">
                                <p className="companyname">
                                  {project.company.CompanyNameAR}
                                </p>
                                <p className="companydetails">
                                  {project.company.Email}
                                </p>
                                <p className="companyplace">
                                  <i className="uil uil-map-marker-shield"></i>
                                  {project.projectcountery.NameAr}
                                </p>
                              </div>
                            </div>
                          </NavLink>
                        </>
                      )}
                      <div className="progressbarsec">
                        <p>
                          <i className="uil uil-shield-exclamation"></i>
                          {project.project.ProjectNameAR}
                        </p>
                        <div className="progress_bar">
                          <span
                            className="progress_in"
                            style={{
                              width:
                                (project.projectcalc / project.project.Target) *
                                  100 +
                                '%',
                            }}
                          ></span>
                        </div>
                        <p>
                          {project.currency.Code +
                            ' ' +
                            project.projectcalc +
                            ' / ' +
                            project.currency.Code +
                            ' ' +
                            project.project.Target}
                        </p>
                        {localStorage.getItem('userInfo') &&
                        user.GroupId == 0 ? (
                          <>
                            <div
                              className="request_fini_project"
                              onClick={openCity.bind(this, 'finice')}
                            >
                              طلب دعم المشروع
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div className="titleheadprojecttwo">
                      <div
                        className="tablinks active"
                        id="defaultOpen"
                        onClick={openCity.bind(this, 'details')}
                      >
                        التفاصيل
                      </div>
                      <div
                        className="tablinks"
                        onClick={openCity.bind(this, 'backers')}
                      >
                        المستثمرون
                      </div>

                      {localStorage.getItem('userInfo') &&
                      project.project.UserID == user.id ? (
                        <>
                          <div
                            className="tablinks"
                            onClick={openCity.bind(this, 'edit')}
                          >
                            تعديل
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div
                      className="viewdetailssec tabcontent dafultopentab"
                      id="details"
                      dangerouslySetInnerHTML={{
                        __html: project.project.ProjectDetails,
                      }}
                    ></div>
                    <div className="viewdetailssec tabcontent" id="finice">
                      <div className="fininctable">
                        <div className="yourfinince" id="yourfinince">
                          <div>يمكنك دعم هذا المشروع بمبلغ</div>
                          <input
                            type="number"
                            name="yourfinince"
                            id="yourfinince"
                            value={finance}
                            onChange={(e) => setfinance(e.target.value)}
                          />
                          <div
                            className="clickinsupport"
                            onClick={supportrequest}
                          >
                            دعم
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="viewdetailssec tabcontent" id="backers">
                      <div className="backers">
                        {project.projectfinance.map((pfinance, index) => (
                          <div className="backer" key={index}>
                            <div className="backer_img">
                              <img
                                src={require('../img/icon-256x256.png')}
                                alt="backerimg"
                              />
                            </div>
                            <div className="backername">
                              {pfinance.FirstName + ' ' + pfinance.LastName}
                            </div>
                            <div className="backerreng">
                              {'دعم هذا المشروع بمبلغ ' +
                                pfinance.finance +
                                ' ' +
                                project.currency.Symbol}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    {localStorage.getItem('userInfo') &&
                    project.project.UserID == user.id ? (
                      <>
                        <div className="viewdetailssec tabcontent" id="edit">
                          <div className="newprojectForm">
                            <input
                              type="text"
                              name="ProjectName"
                              placeholder="اسم المشروع باللغه الانجليزيه"
                              value={ProjectName}
                              onChange={(e) => editProjectName(e.target.value)}
                            />
                            <input
                              type="text"
                              name="ProjectNameAR"
                              placeholder="اسم المشروع"
                              value={ProjectNameAR}
                              onChange={(e) =>
                                editProjectNameAR(e.target.value)
                              }
                            />
                            <input
                              type="text"
                              name="ideainfo"
                              placeholder="وصف قصير عن المشروع"
                              value={IdeaInfo}
                              onChange={(e) => editIdeaInfo(e.target.value)}
                            />
                            <div className="detailsform">
                              <ReactQuill
                                theme="snow"
                                // value={editProjectDetails}
                                onChange={editProjectDetails}
                                modules={modules}
                                formats={formats}
                                value={ProjectDetails}
                              />
                            </div>
                            <div>
                              <input
                                type="number"
                                name="Target"
                                placeholder="ما تريد تحقيقه"
                                value={Target}
                                onChange={(e) => editTarget(e.target.value)}
                              />
                              <select
                                className="currency"
                                value={CurrencyId}
                                onChange={(e) => editCurrencyId(e.target.value)}
                              >
                                <option value="1">جنيه مصري</option>
                                <option value="2">ريال سعودي</option>
                                <option value="3">دولار امريكي</option>
                              </select>
                            </div>
                            <select
                              className="companyselector"
                              value={ProjectNameAR}
                              onChange={(e) => editCompanyID(e.target.value)}
                            >
                              <option value="0">شخصي</option>
                            </select>
                            <div
                              className="newprojectsubmit"
                              onClick={editproject}
                            >
                              تعديل المشروع
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}

                    <div className="titleheadproject">
                      تصفح المزيد من المشاريع
                    </div>
                    {projects.length > 0 ? (
                      <>
                        <div className="projects">{projectsviewAR}</div>
                      </>
                    ) : (
                      <>
                        <div className="Loading">
                          <div className="Loadingspinner"></div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {project && project.result !== 'NotAvailable' ? (
              <>
                <div className="Loading">
                  <div className="Loadingspinner"></div>
                </div>
              </>
            ) : (
              <>
                {localStorage.getItem('Lang') === 'EN' ? (
                  <>
                    <div className="ErrorHandle">
                      <p>
                        <i className="uil uil-exclamation-triangle"></i>This
                        Project Not Found
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="ErrorHandle ar">
                      <p>
                        <i className="uil uil-exclamation-triangle"></i>هذا
                        المشروع غير موجود !
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </motion.div>
    </>
  )
}
