import React from 'react'
import styles from '../style/home.scoped.css'
import { motion } from 'framer-motion'
import { NavLink, useLocation } from 'react-router-dom'

// import Typed from 'react-typed'
// import Typed from 'typed.js'

function Home() {
  return (
    <>
      <motion.div
        className="background" //layoutId="underline"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <img
          src={require('../img/shutterstock_1224851173-scaled.jpg')}
          alt=""
        />
      </motion.div>
      {localStorage.getItem('Lang') === 'EN' ? (
        <>
          <span className="ddop">
            <div className="typesmart">
              {/* <Typed strings={['Here you can find anything']} typeSpeed={40} /> */}
              <p>Your Dream Will Be Fact</p>
              <p id="typed2"></p>
            </div>
          </span>
          {/* <div className="sec2 ar">
            <img src={require('../img/8432.jpg')} alt="Image" width="600" />
            <p>
              من خلال منصة ممول للتمويل الجماعي ، يمكن للمستثمرين استعراض
              الفرص الاستثمارية ، الاستثمار ، وانهاء الاجراءات القانونية عبر
              المنصة فوراً. استثمر الآن ابتداءً من 1000 ريال سعودي فقط . الشركات
              المدرجة على المنصة هي شركات تمت مراجعة معلوماتها والتأكد من صحتها
            </p>
          </div> */}
          <div className="sec2 onflowbox">
            <div className="onsecflow">
              <div className="flowimg">
                <img src={require('../img/investment.png')} alt="investment" />
              </div>
              <h1>الوصول إلى المستثمرين</h1>
              <p>
                من خلال عرض شركتك عبر التمويل الجماعي ، سيكون بإمكانك الوصول إلى
                الآلاف من المستثمرين المستعدين للاستثمار وتسريع الجولة
                الاستثمارية الخاصة بشركتك الناشئة.
              </p>
            </div>
            <div className="onsecflow">
              <div className="flowimg">
                <img
                  src={require('../img/return-on-investment.png')}
                  alt="investment"
                />
              </div>
              <h1>الحصول على سفراء لعلامتك التجارية</h1>
              <p>
                يساعد التمويل الجماعي الشركات الناشئة في الحصول على سفراء
                للعلامات التجارية من خلال مستثمري الشركات الناشئة انفسهم ، حيث
                يقومون بنشر المعلومات والعمل كجهات تسويق لشركتك بعد الاستثمار
                بها.
              </p>
            </div>
            <div className="onsecflow">
              <div className="flowimg">
                <img src={require('../img/loan.png')} alt="investment" />
              </div>
              <h1>التحقق من الجدوى</h1>
              <p>
                سوف يساعدك جمع الأموال من خلال نموذج التمويل الجماعي في التحقق
                من صحة نموذج عملك وخطة عملك واستراتيجية الشركة الناشئة الخاصة بك
                وذلك عبر عرضها على مجموعة كبيرة من الاشخاص الذين يقيمونها عن
                طريق الاستثمار من عدمة
              </p>
            </div>
            <div className="onsecflow">
              <div className="flowimg">
                <img src={require('../img/earning.png')} alt="investment" />
              </div>
              <h1>أسرع طريقه تمويل</h1>
              <p>
                في وقت قياسي ، ستغطي حملة التمويل الجماعي جولة الاستثمار الخاصة
                بشركتك بطريقة أسهل ، وستكون قادرًا على إطلاق حملتك لجمع التمويل
                ومساعدة عملك على النمو بشكل أسرع من طرق التمويل التقليدية
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <span className="ddop">
            <div className="typesmart">
              {/* <Typed strings={['Here you can find anything']} typeSpeed={40} /> */}
              <p>حلمك سيصبح حقيقه</p>
              <p id="typed2"></p>
            </div>
          </span>
          {/* <div className="sec2 ar">
            <img src={require('../img/8432.jpg')} alt="Image" width="600" />
            <p>
              من خلال منصة ممول للتمويل الجماعي ، يمكن للمستثمرين استعراض
              الفرص الاستثمارية ، الاستثمار ، وانهاء الاجراءات القانونية عبر
              المنصة فوراً. استثمر الآن ابتداءً من 1000 ريال سعودي فقط . الشركات
              المدرجة على المنصة هي شركات تمت مراجعة معلوماتها والتأكد من صحتها
            </p>
          </div> */}
          <div className="sec2 onflowbox">
            <div className="onsecflow">
              <div className="flowimg">
                <img src={require('../img/investment.png')} alt="investment" />
              </div>
              <h1>الوصول إلى المستثمرين</h1>
              <p>
                من خلال عرض شركتك عبر التمويل الجماعي ، سيكون بإمكانك الوصول إلى
                الآلاف من المستثمرين المستعدين للاستثمار وتسريع الجولة
                الاستثمارية الخاصة بشركتك الناشئة.
              </p>
            </div>
            <div className="onsecflow">
              <div className="flowimg">
                <img
                  src={require('../img/return-on-investment.png')}
                  alt="investment"
                />
              </div>
              <h1>الحصول على سفراء لعلامتك التجارية</h1>
              <p>
                يساعد التمويل الجماعي الشركات الناشئة في الحصول على سفراء
                للعلامات التجارية من خلال مستثمري الشركات الناشئة انفسهم ، حيث
                يقومون بنشر المعلومات والعمل كجهات تسويق لشركتك بعد الاستثمار
                بها.
              </p>
            </div>
            <div className="onsecflow">
              <div className="flowimg">
                <img src={require('../img/loan.png')} alt="investment" />
              </div>
              <h1>التحقق من الجدوى</h1>
              <p>
                سوف يساعدك جمع الأموال من خلال نموذج التمويل الجماعي في التحقق
                من صحة نموذج عملك وخطة عملك واستراتيجية الشركة الناشئة الخاصة بك
                وذلك عبر عرضها على مجموعة كبيرة من الاشخاص الذين يقيمونها عن
                طريق الاستثمار من عدمة
              </p>
            </div>
            <div className="onsecflow">
              <div className="flowimg">
                <img src={require('../img/earning.png')} alt="investment" />
              </div>
              <h1>أسرع طريقه تمويل</h1>
              <p>
                في وقت قياسي ، ستغطي حملة التمويل الجماعي جولة الاستثمار الخاصة
                بشركتك بطريقة أسهل ، وستكون قادرًا على إطلاق حملتك لجمع التمويل
                ومساعدة عملك على النمو بشكل أسرع من طرق التمويل التقليدية
              </p>
            </div>
          </div>
        </>
      )}
    </>
  )
}
export default Home
