import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

// import axios from 'axios';

function Header() {
  return (
    <>
      {localStorage.getItem('Lang') === 'EN' ? (
        <>
          <div className="header">
            <div className="header_cont">
              <div className="header_center">
                <div className="headerlogo"></div>
              </div>
              <div className="header_center">
                <p>My Account</p>
                {localStorage.getItem('userInfo') ? (
                  <>
                    <NavLink to="/Profile">Profile</NavLink>
                  </>
                ) : (
                  <>
                    <NavLink to="/signin">Sign in</NavLink>
                    <NavLink to="/Signup">Signup</NavLink>
                  </>
                )}
                <NavLink to="/Orders">Our Orders</NavLink>
              </div>
              <div className="header_center">
                <p>Help</p>
                <NavLink to="/howitworks">How It Works </NavLink>
                <NavLink to="/explore">Explore </NavLink>
                <NavLink to="/FAQ">FAQ </NavLink>
              </div>
              <div className="header_center">
                <p>Be Connect</p>
                <NavLink to="/ContactUs">Contact Us </NavLink>
                <NavLink to="/about">About </NavLink>
                <NavLink to="/jobs">jobs </NavLink>
              </div>
              <div className="header_center">
                <p>legal</p>
                <NavLink to="/Terms">Terms & Conditions</NavLink>
                <NavLink to="/PrivacyPolicy">Privacy Policy </NavLink>
              </div>
            </div>
          </div>
          <div className="header_sec">
            <p>all right recerved | Mmol © 2022</p>
            <div className="sociellink">
              <a href="/">
                <i className="uit uil-facebook-f"></i>
              </a>
              <a href="/">
                <i className="uit uil-twitter-alt"></i>
              </a>
              <a href="/">
                <i className="uil uil-instagram"></i>
              </a>
              <a href="/">
                <i className="uil uil-whatsapp"></i>
              </a>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="header ar">
            <div className="header_cont">
              <div className="header_center_ar">
                <div className="headerlogo"></div>
              </div>
              <div className="header_center_ar">
                <p>حسابي</p>
                {localStorage.getItem('userInfo') ? (
                  <>
                    <NavLink to="/Profile">الملف الشخصي</NavLink>
                  </>
                ) : (
                  <>
                    <NavLink to="/signin">تسجيل الدخول</NavLink>
                    <NavLink to="/Signup">تسجيل</NavLink>
                  </>
                )}

                <NavLink to="/Orders">الطلبات</NavLink>
              </div>
              <div className="header_center_ar">
                <p>المساعده</p>
                <NavLink to="/howitworks">كيف تعمل </NavLink>
                <NavLink to="/explore">اكتشف اكثر </NavLink>
                <NavLink to="/FAQ">الاسئلة </NavLink>
              </div>
              <div className="header_center_ar">
                <p>خليك معانا</p>
                <NavLink to="/ContactUs">اتصل بنا</NavLink>
                <NavLink to="/about">عن ممول </NavLink>
                <NavLink to="/jobs">الوظائف </NavLink>
              </div>
              <div className="header_center_ar">
                <p>القوانين</p>
                <NavLink to="/Terms">البنود و الظروف</NavLink>
                <NavLink to="/PrivacyPolicy">سياسة الخصوصية </NavLink>
              </div>
            </div>
          </div>
          <div className="header_sec">
            <p>جميع الحقوق محفوظة | ممول © 2022</p>
            <div className="sociellink">
              <a href="/">
                <i className="uit uil-facebook-f"></i>
              </a>
              <a href="/">
                <i className="uit uil-twitter-alt"></i>
              </a>
              <a href="/">
                <i className="uil uil-instagram"></i>
              </a>
              <a href="/">
                <i className="uil uil-whatsapp"></i>
              </a>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Header
