import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import '../style/crmnavbar.css'
import { motion } from 'framer-motion'
export default class Profile extends Component {
  render() {
    return (
      <>
        {localStorage.getItem('Lang') === 'EN' ? (
          <>
            <div className="navcrm">
              <div className="crmcomplogo">
                <img src={require('../img/logo.png')} alt="" />
              </div>
              <div className="options_crm">
                <div className="rcmblock">
                  <NavLink to="/crm/">
                    <div className="crmblockhead">
                      <i className="uil uil-estate"></i>Home
                    </div>
                  </NavLink>
                </div>
                <div className="rcmblock">
                  <div className="crmblockhead">
                    <i className="uil uil-umbrella"></i>projects
                  </div>
                  <div className="options_crm_c">
                    <NavLink to="/crm/projects">
                      <div className="op">View Projects</div>
                    </NavLink>
                    <NavLink to="/crm/SMS">
                      <div className="op">Review Projects</div>
                    </NavLink>
                  </div>
                </div>
                <div className="rcmblock">
                  <div className="crmblockhead">
                    <i className="uil uil-user-md"></i>Entrepreneur
                  </div>
                  <div className="options_crm_c">
                    <NavLink to="/crm/SMS">
                      <div className="op">View Entrepreneur</div>
                    </NavLink>
                    <NavLink to="/crm/SMS">
                      <div className="op">Companies</div>
                    </NavLink>
                  </div>
                </div>
                <div className="rcmblock">
                  <div className="crmblockhead">
                    <i className="uil uil-user-nurse"></i>Project Financier
                  </div>
                  <div className="options_crm_c">
                    <NavLink to="/crm/SMS">
                      <div className="op">View Project Financier</div>
                    </NavLink>
                  </div>
                </div>
                <div className="rcmblock">
                  <div className="crmblockhead">
                    <i className="uil uil-comment-alt"></i>communication
                  </div>
                  <div className="options_crm_c">
                    <NavLink to="/crm/SMS">
                      <div className="op">SMS</div>
                    </NavLink>
                  </div>
                </div>
                <div className="rcmblock">
                  <div className="crmblockhead">
                    <i className="uil uil-setting"></i>Website Settings
                  </div>
                  <div className="options_crm_c">
                    <NavLink to="/crm/SMS">
                      <div className="op">Admins</div>
                    </NavLink>
                    <NavLink to="/crm/SMS">
                      <div className="op">Website information</div>
                    </NavLink>
                    <NavLink to="/crm/SMS">
                      <div className="op">Website Pages</div>
                    </NavLink>
                    <NavLink to="/crm/SMS">
                      <div className="op">Visitores</div>
                    </NavLink>
                  </div>
                </div>
              </div>
              <NavLink to="/profile">
                <div className="crm_myprofile">
                  <div className="crmprofileimg">
                    <img
                      src={require('../img/icon-256x256.png')}
                      alt="myprofile"
                    />
                  </div>
                  <p>Mostafa Briek</p>
                </div>
              </NavLink>
            </div>
          </>
        ) : (
          <>
            <div className="navcrm ar">
              <div className="crmcomplogo">
                <img src={require('../img/logo.png')} alt="" />
              </div>
              <div className="options_crm">
                <div className="rcmblock">
                  <NavLink to="/crm/">
                    <div className="crmblockhead">
                      <i className="uil uil-estate"></i>الرئيسية
                    </div>
                  </NavLink>
                </div>
                <div className="rcmblock">
                  <div className="crmblockhead">
                    <i className="uil uil-umbrella"></i>المشاريع
                  </div>
                  <div className="options_crm_c">
                    <NavLink to="/crm/projects">
                      <div className="op">عرض المشاريع</div>
                    </NavLink>
                    <NavLink to="/crm/projectpreview">
                      <div className="op">مراجعة المشاريع</div>
                    </NavLink>
                  </div>
                </div>
                <div className="rcmblock">
                  <div className="crmblockhead">
                    <i className="uil uil-user-md"></i>رائد الاعمال
                  </div>
                  <div className="options_crm_c">
                    <NavLink to="/crm/Entrepreneurs">
                      <div className="op">عرض رواد الاعمال</div>
                    </NavLink>
                    <NavLink to="/crm/companies">
                      <div className="op">الشركات</div>
                    </NavLink>
                  </div>
                </div>
                <div className="rcmblock">
                  <div className="crmblockhead">
                    <i className="uil uil-user-nurse"></i>ممولي المشاريع
                  </div>
                  <div className="options_crm_c">
                    <NavLink to="/crm/Projectfinancier">
                      <div className="op">عرض ممولي المشاريع</div>
                    </NavLink>
                    <NavLink to="/crm/Projectfinancierrequest">
                      <div className="op">عرض طلبات التمويل</div>
                    </NavLink>
                  </div>
                </div>
                <div className="rcmblock">
                  <div className="crmblockhead">
                    <i className="uil uil-comment-alt"></i>التواصل
                  </div>
                  <div className="options_crm_c">
                    <NavLink to="/crm/SMS">
                      <div className="op">الرسائل النصيه</div>
                    </NavLink>
                  </div>
                </div>
                <div className="rcmblock">
                  <div className="crmblockhead">
                    <i className="uil uil-setting"></i>اعدادات الموقع
                  </div>
                  <div className="options_crm_c">
                    <NavLink to="/crm/admins">
                      <div className="op">المسئوليين و الصلاحيات</div>
                    </NavLink>
                    <NavLink to="/crm/webinfo">
                      <div className="op">معلومات الموقع</div>
                    </NavLink>
                    <NavLink to="/crm/pages">
                      <div className="op">صفحات الموقع</div>
                    </NavLink>
                    <NavLink to="/crm/vistores">
                      <div className="op">الزوار</div>
                    </NavLink>
                  </div>
                </div>
              </div>
              <NavLink to="/profile">
                <div className="crm_myprofile">
                  <div className="crmprofileimg">
                    <img
                      src={require('../img/icon-256x256.png')}
                      alt="myprofile"
                    />
                  </div>
                  <p>Mostafa Briek</p>
                </div>
              </NavLink>
            </div>
          </>
        )}
      </>
    )
  }
}
