import { React, useEffect, useState } from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
export default function Explore() {
  const location = useLocation()
  const [projects, getProjects] = useState([])
  // const [projectsview, setprojectsview] = useState([])
  // const [projectsviewAR, setprojectsviewAR] = useState([])

  useEffect(() => {
    // document.title = 'Project Name | Mmol'
    // let getProject = getproject(projectid)
    async function getProjectsfun() {
      let result = await fetch('https://api.momoel.com/api/project/view/all')
      result = await result.json()

      getProjects(result)
    }
    getProjectsfun()
  }, [])
  // projects view in English
  const projectsview = projects.projects?.map((project) => (
    <NavLink to={'/project/' + project.project.id} key={project.project.id}>
      <div className="project">
        <div className="projectimg">
          <img
            src={
              'https://api.momoel.com/storage/projects/' +
              project.image.ImageSrc
            }
            alt="projectimg"
          />
        </div>
        <div className="project_name">
          <p className="p_title">{project.project.ProjectName}</p>
          <p>{project.project.IdeaInfo}</p>
          <div className="project_progress">
            <span
              className="progress_in"
              style={{
                width: project.projectclc * 100 + '%',
              }}
            ></span>
          </div>
          <p>
            {project.currency.Code}
            {project.projectclc} / {project.currency.Code}
            {project.project.Target}
          </p>
        </div>
      </div>
    </NavLink>
  ))
  // projects view in Arabic

  const projectsviewAR = projects.projects?.map((project) => (
    <NavLink to={'/project/' + project.project.id} key={project.project.id}>
      <div className="project">
        <div className="projectimg">
          <img
            src={
              'http:localhost:9000/storage/projects/' + project.image.ImageSrc
            }
            alt="projectimg"
          />
        </div>
        <div className="project_name">
          <p className="p_title">{project.project.ProjectNameAR}</p>
          <p>{project.project.IdeaInfo}</p>
          <div className="project_progress">
            <span
              className="progress_in"
              style={{
                width: project.projectclc * 100 + '%',
              }}
            ></span>
          </div>
          <p>
            {project.currency.Code}
            {project.projectclc} / {project.currency.Code}
            {project.project.Target}
          </p>
        </div>
      </div>
    </NavLink>
  ))

  return (
    <>
      <motion.div
        className="explore_d_page" //layoutId="underline"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        {projects && projects.result !== 'NotAvailable' ? (
          <>
            {localStorage.getItem('Lang') === 'EN' ? (
              <>
                <div className="explore">
                  <div className="explore_cover">
                    <img
                      src={require('../img/1660s049823348733680.jpg')}
                      alt="explore"
                    />
                    <p>Explore Projects</p>
                  </div>
                  <div className="projects">{projectsview}</div>
                </div>
              </>
            ) : (
              <>
                <div className="explore ar">
                  <div className="explore_cover">
                    <img
                      src={require('../img/1660s049823348733680.jpg')}
                      alt="explore"
                    />
                    <p>عرض المشاريع</p>
                  </div>
                  <div className="projects">{projectsviewAR}</div>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {projects && projects.result !== 'NotAvailable' ? (
              <>
                <div className="Loading">
                  <div className="Loadingspinner"></div>
                </div>
              </>
            ) : (
              <>
                <div className="Loading">Sorry We Don't Have Projects Now</div>
              </>
            )}
          </>
        )}
      </motion.div>
    </>
  )
}
