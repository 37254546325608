import { React, useEffect, useState } from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import '../style/company.css'
import { motion } from 'framer-motion'

export default function Company() {
  const location = useLocation()
  const { companyid } = useParams()
  const [compamy, getCompany] = useState([])

  useEffect(() => {
    async function getCompanyfun() {
      let result = await fetch(
        'https://api.momoel.com/api/company/view/' + companyid,
      )
      result = await result.json()

      getCompany(result)
    }
    getCompanyfun()
  }, [])
  const openCity = (cityName, event) => {
    var i, tabcontent, tablinks
    tabcontent = document.getElementsByClassName('tabcontent')
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none'
    }
    tablinks = document.getElementsByClassName('tablinks')
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '')
    }
    if (document.getElementById(cityName)) {
      const box = document.getElementById(cityName)
      box.style.display = 'block'
    }
    // console.log('this is:', event)
    event.currentTarget.className += ' active'
  }
  return (
    <>
      <motion.div
        className="explore_d_page" //layoutId="underline"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        {compamy && compamy.result === 'Available' ? (
          <>
            {localStorage.getItem('Lang') === 'EN' ? (
              <>
                <div className="project_cover">
                  <div className="blur">
                    <div className="companyheadplaceincover">
                      <div className="companyimg">
                        <img src={require('../img/company.jpg')} alt="" />
                      </div>
                      <p> {compamy.company.CompanyNameEN}</p>
                    </div>
                  </div>
                  <img
                    src={require('../img/oil-painting-OTZ1.jpg')}
                    alt="explore"
                  />
                </div>
                <div className="titleheadprojecttwo">
                  <div
                    className="tablinks active"
                    id="defaultOpen"
                    onClick={openCity.bind(this, 'info')}
                  >
                    company info
                  </div>
                  {/* <div
                    className="tablinks"
                    onClick={openCity.bind(this, 'projects')}
                  >
                    projects
                  </div> */}
                  {/* <div
                    className="tablinks"
                    onClick={openCity.bind(this, 'backers')}
                  >
                    Backers
                  </div>
                  <div
                    className="tablinks"
                    onClick={openCity.bind(this, 'comunity')}
                  >
                    comunity
                  </div>
                  <div
                    className="tablinks"
                    onClick={openCity.bind(this, 'comunity')}
                  >
                    History
                  </div> */}
                </div>
                <div className="viewin">
                  <div
                    className="viewdetailssec tabcontent dafultopentab"
                    id="info"
                  >
                    info
                  </div>
                  <div className="viewdetailssec tabcontent  " id="backers">
                    <div className="backers">
                      <NavLink to="/profile">
                        <div className="backer">
                          <div className="backer_img">
                            <img
                              src={require('../img/icon-256x256.png')}
                              alt="backerimg"
                            />
                          </div>
                          <div className="backername">Mostafa Briek</div>
                          <div className="backerreng">
                            Backed this project with $80
                          </div>
                        </div>
                      </NavLink>
                      <NavLink to="/profile">
                        <div className="backer">
                          <div className="backer_img">
                            <img
                              src={require('../img/icon-256x256.png')}
                              alt="backerimg"
                            />
                          </div>
                          <div className="backername">Mostafa Briek</div>
                          <div className="backerreng">
                            Backed this project with $80
                          </div>
                        </div>
                      </NavLink>
                      <NavLink to="/profile">
                        <div className="backer">
                          <div className="backer_img">
                            <img
                              src={require('../img/icon-256x256.png')}
                              alt="backerimg"
                            />
                          </div>
                          <div className="backername">Mostafa Briek</div>
                          <div className="backerreng">
                            Backed this project with $80
                          </div>
                        </div>
                      </NavLink>
                      <NavLink to="/profile">
                        <div className="backer">
                          <div className="backer_img">
                            <img
                              src={require('../img/icon-256x256.png')}
                              alt="backerimg"
                            />
                          </div>
                          <div className="backername">Mostafa Briek</div>
                          <div className="backerreng">
                            Backed this project with $80
                          </div>
                        </div>
                      </NavLink>
                      <NavLink to="/profile">
                        <div className="backer">
                          <div className="backer_img">
                            <img
                              src={require('../img/icon-256x256.png')}
                              alt="backerimg"
                            />
                          </div>
                          <div className="backername">Mostafa Briek</div>
                          <div className="backerreng">
                            Backed this project with $80
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                  <div className="viewdetailssec tabcontent  " id="comunity">
                    comunity
                  </div>
                  <div className="viewdetailssec tabcontent " id="projects">
                    <div className="projects">
                      <NavLink to="/project">
                        <div className="project">
                          <div className="projectimg">
                            <img
                              src={require('../img/Project-BG-2005.jpg')}
                              alt=""
                            />
                          </div>
                          <div className="project_name">
                            <p className="p_title">Project Title Name</p>
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Explicabo odio rerum alias eligendi libero
                              quaerat excepturi consequuntur, ipsam veritatis
                              fuga doloremque ex dolores ipsum nesciunt
                              perspiciatis. Accusantium sit eos non aperiam
                              fuga, unde numquam accusamus
                            </p>
                            <div className="project_progress">
                              <span className="progress_in" width="20%"></span>
                            </div>
                            <p>$20.00 / $100.00</p>
                          </div>
                        </div>
                      </NavLink>
                      <NavLink to="/project">
                        <div className="project">
                          <div className="projectimg">
                            <img
                              src={require('../img/Project-BG-2005.jpg')}
                              alt=""
                            />
                          </div>
                          <div className="project_name">
                            <p className="p_title">Project Title Name</p>
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Explicabo odio rerum alias eligendi libero
                              quaerat excepturi consequuntur, ipsam veritatis
                              fuga doloremque ex dolores ipsum nesciunt
                              perspiciatis. Accusantium sit eos non aperiam
                              fuga, unde numquam accusamus
                            </p>
                            <div className="project_progress">
                              <span className="progress_in" width="20%"></span>
                            </div>
                            <p>$20.00 / $100.00</p>
                          </div>
                        </div>
                      </NavLink>
                      <NavLink to="/project">
                        <div className="project">
                          <div className="projectimg">
                            <img
                              src={require('../img/Project-BG-2005.jpg')}
                              alt=""
                            />
                          </div>
                          <div className="project_name">
                            <p className="p_title">Project Title Name</p>
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Explicabo odio rerum alias eligendi libero
                              quaerat excepturi consequuntur, ipsam veritatis
                              fuga doloremque ex dolores ipsum nesciunt
                              perspiciatis. Accusantium sit eos non aperiam
                              fuga, unde numquam accusamus
                            </p>
                            <div className="project_progress">
                              <span className="progress_in" width="20%"></span>
                            </div>
                            <p>$20.00 / $100.00</p>
                          </div>
                        </div>
                      </NavLink>
                      <NavLink to="/project">
                        <div className="project">
                          <div className="projectimg">
                            <img
                              src={require('../img/Project-BG-2005.jpg')}
                              alt=""
                            />
                          </div>
                          <div className="project_name">
                            <p className="p_title">Project Title Name</p>
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Explicabo odio rerum alias eligendi libero
                              quaerat excepturi consequuntur, ipsam veritatis
                              fuga doloremque ex dolores ipsum nesciunt
                              perspiciatis. Accusantium sit eos non aperiam
                              fuga, unde numquam accusamus
                            </p>
                            <div className="project_progress">
                              <span className="progress_in" width="20%"></span>
                            </div>
                            <p>$20.00 / $100.00</p>
                          </div>
                        </div>
                      </NavLink>
                      <NavLink to="/project">
                        <div className="project">
                          <div className="projectimg">
                            <img
                              src={require('../img/Project-BG-2005.jpg')}
                              alt=""
                            />
                          </div>
                          <div className="project_name">
                            <p className="p_title">Project Title Name</p>
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Explicabo odio rerum alias eligendi libero
                              quaerat excepturi consequuntur, ipsam veritatis
                              fuga doloremque ex dolores ipsum nesciunt
                              perspiciatis. Accusantium sit eos non aperiam
                              fuga, unde numquam accusamus
                            </p>
                            <div className="project_progress">
                              <span className="progress_in" width="20%"></span>
                            </div>
                            <p>$20.00 / $100.00</p>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="ar">
                  <div className="project_cover">
                    <div className="blur">
                      <div className="companyheadplaceincover">
                        <div className="companyimg">
                          <img src={require('../img/company.jpg')} alt="" />
                        </div>
                        <p> {compamy.company.CompanyNameAR}</p>
                      </div>
                    </div>
                    <img
                      src={require('../img/oil-painting-OTZ1.jpg')}
                      alt="explore"
                    />
                  </div>
                  <div className="titleheadprojecttwo">
                    <div
                      className="tablinks active"
                      id="defaultOpen"
                      onClick={openCity.bind(this, 'info')}
                    >
                      معلومات عن الشركة
                    </div>
                    {/* <div
                      className="tablinks"
                      onClick={openCity.bind(this, 'projects')}
                    >
                      المشاريع
                    </div> */}
                    {/* <div
                      className="tablinks"
                      onClick={openCity.bind(this, 'backers')}
                    >
                      المستثمرون
                    </div>
                    <div
                      className="tablinks"
                      onClick={openCity.bind(this, 'comunity')}
                    >
                      المجتمع الداخلي
                    </div>
                    <div
                      className="tablinks"
                      onClick={openCity.bind(this, 'comunity')}
                    >
                      التاريخ
                    </div> */}
                  </div>
                  <div className="viewin">
                    <div
                      className="viewdetailssec tabcontent dafultopentab"
                      id="info"
                    >
                      <table>
                        <tbody>
                          <tr>
                            <td>الاسم </td>
                            <td>{compamy.company.CompanyNameAR}</td>
                          </tr>
                          <tr>
                            <td>الاسم </td>
                            <td>{compamy.company.CompanyNameEN}</td>
                          </tr>

                          <tr>
                            <td>البريد الالكتروني</td>
                            <td>{compamy.company.Email}</td>
                          </tr>
                          <tr>
                            <td>رقم الهاتف</td>
                            <td>{compamy.company.PhoneNumber}</td>
                          </tr>
                          <tr>
                            <td>العنوان</td>
                            <td>{compamy.company.Address}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="viewdetailssec tabcontent  " id="backers">
                      <div className="backers">
                        <NavLink to="/profile">
                          <div className="backer">
                            <div className="backer_img">
                              <img
                                src={require('../img/icon-256x256.png')}
                                alt="backerimg"
                              />
                            </div>
                            <div className="backername">Mostafa Briek</div>
                            <div className="backerreng">
                              Backed this project with $80
                            </div>
                          </div>
                        </NavLink>
                        <NavLink to="/profile">
                          <div className="backer">
                            <div className="backer_img">
                              <img
                                src={require('../img/icon-256x256.png')}
                                alt="backerimg"
                              />
                            </div>
                            <div className="backername">Mostafa Briek</div>
                            <div className="backerreng">
                              Backed this project with $80
                            </div>
                          </div>
                        </NavLink>
                        <NavLink to="/profile">
                          <div className="backer">
                            <div className="backer_img">
                              <img
                                src={require('../img/icon-256x256.png')}
                                alt="backerimg"
                              />
                            </div>
                            <div className="backername">Mostafa Briek</div>
                            <div className="backerreng">
                              Backed this project with $80
                            </div>
                          </div>
                        </NavLink>
                        <NavLink to="/profile">
                          <div className="backer">
                            <div className="backer_img">
                              <img
                                src={require('../img/icon-256x256.png')}
                                alt="backerimg"
                              />
                            </div>
                            <div className="backername">Mostafa Briek</div>
                            <div className="backerreng">
                              Backed this project with $80
                            </div>
                          </div>
                        </NavLink>
                        <NavLink to="/profile">
                          <div className="backer">
                            <div className="backer_img">
                              <img
                                src={require('../img/icon-256x256.png')}
                                alt="backerimg"
                              />
                            </div>
                            <div className="backername">Mostafa Briek</div>
                            <div className="backerreng">
                              Backed this project with $80
                            </div>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                    <div className="viewdetailssec tabcontent  " id="comunity">
                      comunity
                    </div>
                    <div className="viewdetailssec tabcontent " id="projects">
                      <div className="projects">
                        <NavLink to="/project">
                          <div className="project">
                            <div className="projectimg">
                              <img
                                src={require('../img/Project-BG-2005.jpg')}
                                alt=""
                              />
                            </div>
                            <div className="project_name">
                              <p className="p_title">Project Title Name</p>
                              <p>
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Explicabo odio rerum alias
                                eligendi libero quaerat excepturi consequuntur,
                                ipsam veritatis fuga doloremque ex dolores ipsum
                                nesciunt perspiciatis. Accusantium sit eos non
                                aperiam fuga, unde numquam accusamus
                              </p>
                              <div className="project_progress">
                                <span
                                  className="progress_in"
                                  width="20%"
                                ></span>
                              </div>
                              <p>$20.00 / $100.00</p>
                            </div>
                          </div>
                        </NavLink>
                        <NavLink to="/project">
                          <div className="project">
                            <div className="projectimg">
                              <img
                                src={require('../img/Project-BG-2005.jpg')}
                                alt=""
                              />
                            </div>
                            <div className="project_name">
                              <p className="p_title">Project Title Name</p>
                              <p>
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Explicabo odio rerum alias
                                eligendi libero quaerat excepturi consequuntur,
                                ipsam veritatis fuga doloremque ex dolores ipsum
                                nesciunt perspiciatis. Accusantium sit eos non
                                aperiam fuga, unde numquam accusamus
                              </p>
                              <div className="project_progress">
                                <span
                                  className="progress_in"
                                  width="20%"
                                ></span>
                              </div>
                              <p>$20.00 / $100.00</p>
                            </div>
                          </div>
                        </NavLink>
                        <NavLink to="/project">
                          <div className="project">
                            <div className="projectimg">
                              <img
                                src={require('../img/Project-BG-2005.jpg')}
                                alt=""
                              />
                            </div>
                            <div className="project_name">
                              <p className="p_title">Project Title Name</p>
                              <p>
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Explicabo odio rerum alias
                                eligendi libero quaerat excepturi consequuntur,
                                ipsam veritatis fuga doloremque ex dolores ipsum
                                nesciunt perspiciatis. Accusantium sit eos non
                                aperiam fuga, unde numquam accusamus
                              </p>
                              <div className="project_progress">
                                <span
                                  className="progress_in"
                                  width="20%"
                                ></span>
                              </div>
                              <p>$20.00 / $100.00</p>
                            </div>
                          </div>
                        </NavLink>
                        <NavLink to="/project">
                          <div className="project">
                            <div className="projectimg">
                              <img
                                src={require('../img/Project-BG-2005.jpg')}
                                alt=""
                              />
                            </div>
                            <div className="project_name">
                              <p className="p_title">Project Title Name</p>
                              <p>
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Explicabo odio rerum alias
                                eligendi libero quaerat excepturi consequuntur,
                                ipsam veritatis fuga doloremque ex dolores ipsum
                                nesciunt perspiciatis. Accusantium sit eos non
                                aperiam fuga, unde numquam accusamus
                              </p>
                              <div className="project_progress">
                                <span
                                  className="progress_in"
                                  width="20%"
                                ></span>
                              </div>
                              <p>$20.00 / $100.00</p>
                            </div>
                          </div>
                        </NavLink>
                        <NavLink to="/project">
                          <div className="project">
                            <div className="projectimg">
                              <img
                                src={require('../img/Project-BG-2005.jpg')}
                                alt=""
                              />
                            </div>
                            <div className="project_name">
                              <p className="p_title">Project Title Name</p>
                              <p>
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Explicabo odio rerum alias
                                eligendi libero quaerat excepturi consequuntur,
                                ipsam veritatis fuga doloremque ex dolores ipsum
                                nesciunt perspiciatis. Accusantium sit eos non
                                aperiam fuga, unde numquam accusamus
                              </p>
                              <div className="project_progress">
                                <span
                                  className="progress_in"
                                  width="20%"
                                ></span>
                              </div>
                              <p>$20.00 / $100.00</p>
                            </div>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {compamy && compamy.result !== 'notfound' ? (
              <>
                <div className="Loading">
                  <div className="Loadingspinner"></div>
                </div>
              </>
            ) : (
              <>
                {localStorage.getItem('Lang') === 'EN' ? (
                  <>
                    <div className="ErrorHandle">
                      <p>
                        <i className="uil uil-exclamation-triangle"></i>This
                        company Not Found
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="ErrorHandle ar">
                      <p>
                        <i className="uil uil-exclamation-triangle"></i>هذه
                        الشركه غير موجود !
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </motion.div>
    </>
  )
}
