import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import '../style/sign.css'
import { motion } from 'framer-motion'
export default class Profile extends Component {
  render() {
    return (
      <>
        <motion.div
          className="crm"
          initial={{ width: '0' }}
          animate={{ width: '100%' }}
          exit={{ x: window.innerWidth, transaction: { duration: 0.1 } }}
        ></motion.div>
      </>
    )
  }
}
