import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import '../style/sign.css'
import { motion } from 'framer-motion'
// import { MDBCollapse, MDBBtn } from 'mdb-react-ui-kit'
// import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import Cookies from 'universal-cookie'

export default function Signin() {
  const goto = useNavigate()
  useEffect(() => {
    if (localStorage.getItem('userInfo')) {
      goto('/')
      // goto(-1)
    }
  }, [])

  const [email, Getusername] = useState('')
  const [password, Getuserpassword] = useState('')
  const [error, errorhandle] = useState('')

  async function login() {
    let user = { email, password }
    let url = 'https://api.momoel.com/api/signin'
    let result = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(user),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    result = await result.json()
    // console.warn(result)
    if (result.code === 20) {
      localStorage.setItem('userimg', JSON.stringify(result.img))
      localStorage.setItem('userInfo', JSON.stringify(result.user))
      const cookies = new Cookies()
      cookies.set('_user', result.session._token, { path: '/' })
      // console.log(cookies.get('_user')) // Pacman
      // goto('/')
      goto(-1)
    }
    // handle error in login
    if (result.code === 401) {
      if (localStorage.getItem('Lang') === 'EN') {
        document.getElementById('errorhandle').innerHTML =
          'Your Email Or Password Incorrect , Try again '
      } else {
        document.getElementById('errorhandle').innerHTML =
          'البريد الالكتروني او الرقم السري غير صحيح'
      }
    }
  }
  return (
    <>
      <motion.div
        className="signin_c" //layoutId="underline"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <span className="loplock">
          <i className="uil uil-padlock"></i>
        </span>
        {localStorage.getItem('Lang') === 'EN' ? (
          <>
            <div className="headername">
              <p>Have an Account ?</p>
              <h1>Login</h1>
            </div>
            <div className="boxin">
              <div className="boxinput_">
                <input
                  type="text"
                  name="username"
                  placeholder="username or email"
                  onChange={(e) => Getusername(e.target.value)}
                />
                <i className="uil uil-user-square"></i>
              </div>
              <div className="boxinput_">
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={(e) => Getuserpassword(e.target.value)}
                />
                <i className="uil uil-lock"></i>
              </div>
              <div className="boxinput_ signinsubmit">
                <input
                  type="submit"
                  name="submit"
                  value="signin"
                  onClick={login}
                  onKeyDown={login}
                />
                <i className="uil uil-signin"></i>
              </div>
            </div>
            <div className="boxinput__">
              <input type="checkbox" name="remember"></input>
              <label htmlFor="remember">Remember Me</label>
            </div>
            <div className="boxch" id="errorhandle"></div>
            <div className="boxch">
              <NavLink to="/signin">Forget Password</NavLink>
              <NavLink to="/signup">create New Account</NavLink>
            </div>
          </>
        ) : (
          <>
            <div className="headername ar">
              <p>لديك حساب</p>
              <h1>سجل دخولك الان</h1>
            </div>
            <div className="boxin ar">
              <div className="boxinput_">
                <input
                  type="text"
                  name="username"
                  placeholder="البريد الالكتروني"
                  onChange={(e) => Getusername(e.target.value)}
                />
                <i className="uil uil-user-square"></i>
              </div>
              <div className="boxinput_">
                <input
                  type="password"
                  name="password"
                  placeholder="الرقم السري"
                  onChange={(e) => Getuserpassword(e.target.value)}
                />
                <i className="uil uil-lock"></i>
              </div>
              <div className="boxinput_ signinsubmit">
                <input
                  type="submit"
                  name="submit"
                  value="تسجيل دخول"
                  onClick={login}
                  onKeyDown={login}
                />
                <i className="uil uil-signin"></i>
              </div>
            </div>
            <div className="boxinput__ ar">
              <input type="checkbox" name="remember"></input>
              <label htmlFor="remember">تذكرني</label>
            </div>
            <div className="boxch ar" id="errorhandle"></div>
            <div className="boxch ar">
              <NavLink to="/signin">نسيت رقمك السري</NavLink>
              <NavLink to="/signup">تسجيل حساب جديد</NavLink>
            </div>
          </>
        )}
        {/* <MDBBtn>Button</MDBBtn> */}
      </motion.div>
    </>
  )
}
