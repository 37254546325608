import React, { useState, useEffect } from 'react'
import {
  createRoutesFromChildren,
  NavLink,
  useNavigate,
} from 'react-router-dom'
import '../style/sign.css'
import { motion } from 'framer-motion'
import '../style/forms.css'

// import react quill
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

export default function Addnewproject() {
  const goto = useNavigate()
  // get user data
  let user = JSON.parse(localStorage.getItem('userInfo'))
  useEffect(() => {
    if (!localStorage.getItem('userInfo')) {
      goto('/signin')
    } else {
      let user = JSON.parse(localStorage.getItem('userInfo'))
    }
    async function getcompaniesfun() {
      let result = await fetch(
        'https://api.momoel.com/api/user/company/' + user.id,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      )
      result = await result.json()

      getcompanies(result)
    }
    getcompaniesfun()
  }, [])
  const [companies, getcompanies] = useState([])

  const companiespro = companies.map((company) => (
    <>
      <option key={company.id} value={company.id}>
        {company.CompanyNameAR}
      </option>
    </>
  ))
  // form validation
  const [ProjectName, setProjectName] = useState('')
  const [ProjectNameAR, setProjectNameAR] = useState('')
  const [ProjectDetails, setProjectDetails] = useState('')
  const [IdeaInfo, setIdeaInfo] = useState('')
  const [Target, setTarget] = useState('0')
  const [CurrencyId, setCurrencyId] = useState('1')
  const [CompanyID, setCompanyID] = useState('0')
  const [UserID, setUserID] = useState(user?.id)

  async function addproject() {
    let project = {
      ProjectName,
      ProjectNameAR,
      ProjectDetails,
      IdeaInfo,
      Target,
      CurrencyId,
      CompanyID,
      UserID,
    }
    let url = 'https://api.momoel.com/api/project/add'
    let result = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(project),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    result = await result.json()
    console.warn('result', result)
    if (result.project) {
      goto('/project/' + result.projectid)
    }
  }
  function setProjectDetailsfun(e) {
    // console.log('ddssd')
    // console.log(e)
    setProjectDetails(e)
  }

  // quill options
  let value = ''
  let setValue = ''
  let modules = {
    toolbar: [
      [{ font: [] }],
      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction
      [{ align: [] }],

      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript

      ['link', 'image', 'video', 'formula'], // add's image support
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme

      ['clean'], // remove formatting button
    ],
  }

  let formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ]
  // end of quill options

  return (
    <>
      <motion.div
        className="addnewproject" //layoutId="underline"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        {localStorage.getItem('Lang') === 'EN' ? (
          <>
            <div className="newproject">
              <div className="alert">
                Hello, {user.FirstName} You are just steps away from adding your
                new project
              </div>
              <div className="newprojectForm">
                <input
                  type="text"
                  name="ProjectName"
                  placeholder="Project Name By Arabic"
                />
                <input
                  type="text"
                  name="ProjectName"
                  placeholder="Project Name By English"
                />
                <div className="detailsform">
                  <ReactQuill
                    theme="snow"
                    value={value}
                    onChange={setValue}
                    modules={modules}
                    formats={formats}
                  />
                </div>
                <div>
                  <input
                    type="number"
                    name="Target"
                    placeholder="Your Target"
                  />
                  <select className="currency">
                    <option value="EGP">Egypt Pounds</option>
                    <option value="SAR">Saudi Riyals</option>
                    <option value="USD">American dollar</option>
                  </select>
                </div>
                <select className="companyselector">
                  <option value="CompanyID">company 1</option>
                  <option value="CompanyID">company 2</option>
                </select>
                <div className="newprojectsubmit">Add Your Project</div>
              </div>
            </div>
          </>
        ) : (
          <>
            {localStorage.getItem('userInfo') ? (
              <div className="newproject ar">
                <div className="alert">
                  اهلا {user.FirstName} انت علي بعد خطوات من اضافة مشروعك الجديد
                </div>
                <div className="newprojectForm">
                  <input
                    type="text"
                    name="ProjectName"
                    placeholder="اسم المشروع"
                    onChange={(e) => setProjectNameAR(e.target.value)}
                  />
                  <input
                    type="text"
                    name="ProjectName"
                    placeholder="اسم المشروع باللغه الانجليزيه"
                    onChange={(e) => setProjectName(e.target.value)}
                  />
                  <input
                    type="text"
                    name="ideainfo"
                    placeholder="وصف قصير عن المشروع"
                    onChange={(e) => setIdeaInfo(e.target.value)}
                  />
                  <div className="detailsform">
                    <ReactQuill
                      theme="snow"
                      // value={setProjectDetails}
                      onChange={setProjectDetails}
                      modules={modules}
                      formats={formats}
                    />
                  </div>
                  <div>
                    <input
                      type="number"
                      name="Target"
                      placeholder="ما تريد تحقيقه"
                      onChange={(e) => setTarget(e.target.value)}
                    />
                    <select
                      className="currency"
                      onChange={(e) => setCurrencyId(e.target.value)}
                    >
                      <option value="1">جنيه مصري</option>
                      <option value="2">ريال سعودي</option>
                      <option value="3">دولار امريكي</option>
                    </select>
                  </div>
                  <select
                    className="companyselector"
                    onChange={(e) => setCompanyID(e.target.value)}
                  >
                    <option value="0">شخصي</option>
                    {companiespro}
                  </select>
                  <div className="newprojectsubmit" onClick={addproject}>
                    اضافة المشروع
                  </div>
                </div>
              </div>
            ) : null}
          </>
        )}
      </motion.div>
    </>
  )
}
