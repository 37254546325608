import React, { useState, useEffect } from 'react'
import axios from 'axios'

import {
  createRoutesFromChildren,
  NavLink,
  useNavigate,
} from 'react-router-dom'
import '../style/sign.css'
import { motion } from 'framer-motion'
import Select from 'react-select'

export default function Signup() {
  const goto = useNavigate()
  useEffect(() => {
    if (localStorage.getItem('userInfo')) {
      goto('/')
    }
  }, [])

  const [result, setresult] = useState()

  const [FirstName, setFirstname] = useState('')
  const [LastName, setLastname] = useState('')
  const [Phonenumber, setPhonenumber] = useState('')
  const [CountryId, setcountry] = useState('')
  const [StateId, setStateId] = useState('')
  const [GroupId, setGroupId] = useState('6')
  const [email, setemail] = useState('')
  const [Address, setAddress] = useState('')
  const [password, setpassword] = useState('')
  const [repassword, setrepassword] = useState('')

  // countries and state
  const [countries, getcountries] = useState('')
  const [state, getstate] = useState('')

  useEffect(() => {
    if (FirstName != '' && FirstName.length > 2) {
      document.getElementById('firstname').classList.remove('errorhandle')
    } else {
      document.getElementById('firstname').classList.add('errorhandle')
    }
  }, [FirstName])

  useEffect(() => {
    if (LastName != '' && LastName.length > 2) {
      document.getElementById('lastname').classList.remove('errorhandle')
    } else {
      document.getElementById('lastname').classList.add('errorhandle')
    }
  }, [LastName])

  useEffect(() => {
    if (Phonenumber != '') {
      document.getElementById('Phonenumber').classList.remove('errorhandle')
    } else {
      document.getElementById('Phonenumber').classList.add('errorhandle')
    }
  }, [Phonenumber])

  useEffect(() => {
    if (CountryId != '') {
      document.getElementById('CountryId').classList.remove('errorhandle')
    } else {
      document.getElementById('CountryId').classList.add('errorhandle')
    }
  }, [CountryId])

  useEffect(() => {
    if (GroupId != '') {
      document.getElementById('GroupId').classList.remove('errorhandle')
    } else {
      document.getElementById('GroupId').classList.add('errorhandle')
    }
  }, [GroupId])
  useEffect(() => {
    if (Address != '') {
      document.getElementById('Address').classList.remove('errorhandle')
    } else {
      document.getElementById('Address').classList.add('errorhandle')
    }
  }, [Address])
  useEffect(() => {
    if (StateId != '') {
      document.getElementById('StateId').classList.remove('errorhandle')
    } else {
      document.getElementById('StateId').classList.add('errorhandle')
    }
  }, [StateId])
  useEffect(() => {
    if (email != '') {
      document.getElementById('email').classList.remove('errorhandle')
    } else {
      document.getElementById('email').classList.add('errorhandle')
    }
  }, [email])
  useEffect(() => {
    if (password != '') {
      document.getElementById('password').classList.remove('errorhandle')
    } else {
      document.getElementById('password').classList.add('errorhandle')
    }
  }, [password])
  useEffect(() => {
    if (repassword != '') {
      document.getElementById('repassword').classList.remove('errorhandle')
    } else {
      document.getElementById('repassword').classList.add('errorhandle')
    }
  }, [repassword])

  async function signup() {
    var error = []
    if (FirstName == '' && FirstName.length < 2) {
      document.getElementById('firstname').classList.add('errorhandle')
      error += 1
    }

    if (LastName == '' && LastName.length < 2) {
      document.getElementById('lastname').classList.add('errorhandle')
      error += 1
    }
    if (Phonenumber == '') {
      document.getElementById('Phonenumber').classList.add('errorhandle')
      error += 1
    }
    if (CountryId == '') {
      document.getElementById('CountryId').classList.add('errorhandle')
      error += 1
    }
    if (GroupId == '') {
      document.getElementById('GroupId').classList.add('errorhandle')
      error += 1
    }
    if (password == '') {
      document.getElementById('password').classList.add('errorhandle')
      error += 1
    }
    if (StateId == '') {
      document.getElementById('StateId').classList.add('errorhandle')
      error += 1
    }
    if (email == '') {
      document.getElementById('email').classList.add('errorhandle')
      error += 1
    }
    if (Address == '') {
      document.getElementById('Address').classList.add('errorhandle')
      error += 1
    }
    if (repassword == '') {
      document.getElementById('repassword').classList.add('errorhandle')
      error += 1
    }
    // console.log(error.length)
    if (error.length == 0) {
      let user = {
        FirstName,
        LastName,
        Phonenumber,
        CountryId,
        password,
        StateId,
        GroupId,
        email,
        Address,
      }

      await axios
        .post('https://api.momoel.com/api/register', user, {
          headers: { 'Access-Control-Allow-Origin': '*' },
        })
        .then((response) => {
          // setPost(response.data)
          setresult(response.data)
          if (!result.errors || result) {
            if (localStorage.setItem('userInfo', JSON.stringify(result.user))) {
              localStorage.setItem(
                'userimg',
                JSON.stringify({
                  ImageSrc: 'YSoTOe0yu2WvagoJ0vxY5FS7o5NK6GoDrbgw7uzr.jpg',
                }),
              )
              goto('/profile')
            }
          } else {
            if (result.errors.email) {
              if (localStorage.getItem('Lang') === 'EN') {
                document.getElementById('errorhandle').innerHTML =
                  'Your Email Incorrect , Try another one '
              } else {
                document.getElementById('errorhandle').innerHTML =
                  'البريد الالكتروني غير صحيح'
              }
              document.getElementById('email').classList.add('errorhandle')
            }
          }
        })
    }
  }

  useEffect(() => {
    // document.title = 'Project Name | Mmol'
    // let getProject = getproject(projectid)
    function getcountriesfun() {
      if (localStorage.getItem('Lang') === 'EN') {
        axios
          .post('https://api.momoel.com/api/user/countries/en')
          .then((response) => {
            // setPost(response.data)
            getcountries(response.data)
          })
      } else {
        axios
          .post('https://api.momoel.com/api/user/countries/ar')
          .then((response) => {
            // setPost(response.data)
            getcountries(response.data)
          })
      }
    }
    getcountriesfun()
  }, [])

  function countryselectid(e) {
    // console.log(e.value)
    setcountry(e.value)
    // get state from country choice
    async function getstatefun() {
      if (localStorage.getItem('Lang') === 'EN') {
        axios
          .post('https://api.momoel.com/api/user/gov/en/' + e.value)
          .then((response) => {
            // setPost(response.data)
            getstate(response.data)
          })
      } else {
        axios
          .post('https://api.momoel.com/api/user/gov/ar/' + e.value)
          .then((response) => {
            // setPost(response.data)
            getstate(response.data)
          })
      }
    }
    getstatefun()
  }
  function setStateIdfun(e) {
    setStateId(e.value)
  }
  return (
    <>
      <motion.div
        className="signup_c" //layoutId="underline"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        {localStorage.getItem('Lang') === 'EN' ? (
          <>
            <span className="loplock">
              <i className="uil uil-user-plus"></i>
            </span>
            <div className="headername">
              <p>Have an Account ?</p>
              <h1>Create it Now</h1>
            </div>
            <div className="boxin">
              <div className="boxinput_" id="firstname">
                <input
                  type="text"
                  name="FirstName"
                  placeholder="First Name"
                  value={FirstName}
                  onChange={(e) => setFirstname(e.target.value)}
                />
                <i className="uil uil-user"></i>
              </div>
              <div className="boxinput_" id="lastname">
                <input
                  type="text"
                  name="LastName"
                  placeholder="Last Name"
                  value={LastName}
                  onChange={(e) => setLastname(e.target.value)}
                />
                <i className="uil uil-user"></i>
              </div>
              <div className="boxinput_" id="Phonenumber">
                <input
                  type="number"
                  name="Phonenumber"
                  placeholder="Phonenumber"
                  value={Phonenumber}
                  onChange={(e) => setPhonenumber(e.target.value)}
                />
                <i className="uil uil-mobile-android"></i>
              </div>
              <div className="boxinput_" id="CountryId">
                <Select
                  name="CountryId"
                  options={countries}
                  onChange={countryselectid}
                  // isLoading={false}
                  // isRtl={false}
                  placeholder="select country"
                />
                <i className="uil uil-globe"></i>
              </div>
              <div className="boxinput_" id="StateId">
                <Select
                  name="stateID"
                  options={state}
                  onChange={setStateIdfun}
                  // isLoading={false}
                  // isRtl={false}
                  placeholder="select governorate"
                />
                <i className="uil uil-globe"></i>
              </div>
              <div className="boxinput_" id="GroupId">
                <select
                  name="GroupId"
                  value={GroupId}
                  onChange={(e) => setGroupId(e.target.value)}
                >
                  <option value="6">Project Finincer</option>
                  <option value="7">investors</option>
                </select>
              </div>
              <div className="boxinput_" id="email">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
                <i className="uil uil-envelope"></i>
              </div>
              <div className="boxinput_" id="Address">
                <input
                  type="text"
                  name="Address"
                  placeholder="Address"
                  value={Address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <i className="uil uil-map-marker-shield"></i>
              </div>

              <div className="boxinput_" id="password">
                <input
                  type="password"
                  name="userpassword"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setpassword(e.target.value)}
                />
                <i className="uil uil-lock"></i>
              </div>
              <div className="boxinput_" id="repassword">
                <input
                  type="password"
                  name="userpassword"
                  placeholder="Re Enter Password"
                  value={repassword}
                  onChange={(e) => setrepassword(e.target.value)}
                />
                <i className="uil uil-lock"></i>
              </div>
              <div className="boxinput_ signinsubmit">
                <input
                  type="submit"
                  name="submit"
                  value="Sign Up"
                  onClick={signup}
                />
                <i className="uil uil-signin"></i>
              </div>
            </div>
            <div className="boxch" id="errorhandle"></div>
            <div className="boxch">
              <NavLink to="/signin">Have an Account ?! sign in</NavLink>
            </div>
          </>
        ) : (
          <>
            <span className="loplock">
              <i className="uil uil-user-plus"></i>
            </span>
            <div className="headername ar">
              <p>هل لديك حساب</p>
              <h1>قم بانشاء حساب الان</h1>
            </div>
            <div className="boxin ar">
              <div className="boxinput_" id="firstname">
                <input
                  type="text"
                  name="FirstName"
                  placeholder="الاسم الاول"
                  value={FirstName}
                  onChange={(e) => setFirstname(e.target.value)}
                />
                <i className="uil uil-user"></i>
              </div>
              <div className="boxinput_" id="lastname">
                <input
                  type="text"
                  name="LastName"
                  placeholder="الاسم الاخير"
                  value={LastName}
                  onChange={(e) => setLastname(e.target.value)}
                />
                <i className="uil uil-user"></i>
              </div>
              <div className="boxinput_" id="Phonenumber">
                <input
                  type="number"
                  name="Phonenumber"
                  placeholder="رقم الهاتف"
                  value={Phonenumber}
                  onChange={(e) => setPhonenumber(e.target.value)}
                />
                <i className="uil uil-mobile-android"></i>
              </div>
              <div className="boxinput_" id="CountryId">
                <Select
                  name="CountryId"
                  options={countries}
                  onChange={countryselectid}
                  // isLoading={false}
                  isRtl={true}
                  placeholder="اختر الدولة"
                />
                <i className="uil uil-globe"></i>
              </div>
              <div className="boxinput_" id="StateId">
                <Select
                  name="stateID"
                  options={state}
                  onChange={setStateIdfun}
                  // isLoading={false}
                  isRtl={true}
                  placeholder="اختر المدينه"
                />
                <i className="uil uil-globe"></i>
              </div>
              <div className="boxinput_" id="GroupId">
                <select
                  name="GroupId"
                  value={GroupId}
                  onChange={(e) => setGroupId(e.target.value)}
                >
                  <option value="6">ممول مشاريع</option>
                  <option value="7">رائد اعمال</option>
                </select>
              </div>
              <div className="boxinput_" id="email">
                <input
                  type="email"
                  name="email"
                  placeholder="البريد الالكتروني"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
                <i className="uil uil-envelope"></i>
              </div>
              <div className="boxinput_" id="Address">
                <input
                  type="text"
                  name="Address"
                  placeholder="العنوان"
                  value={Address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <i className="uil uil-map-marker-shield"></i>
              </div>

              <div className="boxinput_" id="password">
                <input
                  type="password"
                  name="userpassword"
                  placeholder="الرقم السري"
                  value={password}
                  onChange={(e) => setpassword(e.target.value)}
                />
                <i className="uil uil-lock"></i>
              </div>
              <div className="boxinput_" id="repassword">
                <input
                  type="password"
                  name="userpassword"
                  placeholder="اعد كتابة الرقم السري"
                  value={repassword}
                  onChange={(e) => setrepassword(e.target.value)}
                />
                <i className="uil uil-lock"></i>
              </div>
              <div className="boxinput_ signinsubmit">
                <input
                  type="submit"
                  name="submit"
                  value="تسجيل"
                  onClick={signup}
                />
                <i className="uil uil-signin"></i>
              </div>
            </div>
            <div className="boxch ar" id="errorhandle"></div>
            <div className="boxch ar">
              <NavLink to="/signin">هل لديك حساب ؟! سجل دخول الان</NavLink>
            </div>
          </>
        )}
      </motion.div>
    </>
  )
}
