import { React, useEffect } from 'react'
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

// page components for main website
import Signup from '../components/signup'
import Signin from '../components/signin'
import Home from '../components/home'
import Profile from '../components/profile'
import Navbar from '../components/navbar'
import Explore from '../components/explore'
import Howitworks from '../components/howitworks'
import Start from '../components/start'
import Projects from '../components/projects'
import Project from '../components/project'
import Company from '../components/company'
import Addnewproject from '../components/addnewproject'
import Addnewcompany from '../components/addcompany'
// page components for CRM
import Header from '../components/header'
import CrmNavbar from '../crmcomponents/navbar'
import CrmHeader from '../crmcomponents/header'
import CrmHome from '../crmcomponents/home'
import CrmLogin from '../crmcomponents/login'
import CrmProjects from '../crmcomponents/CrmProjects'
import Crmpages from '../crmcomponents/crmpages'
import CrmVistores from '../crmcomponents/webvistores'
import CrmpreviewProjects from '../crmcomponents/CrmpreviewProjects'
import Entrepreneurs from '../crmcomponents/entrepreneurs'
import Companies from '../crmcomponents/companies'
import Projectfinancier from '../crmcomponents/Projectfinancier'
import Webconfig from '../crmcomponents/webinfo'
import Pages from '../crmcomponents/pages'
import Projectfinancrequest from '../crmcomponents/crmviewprojectsfinincerequest'
function Pageroute() {
  const location = useLocation()
  // console.log('hash', location.hash)
  console.log('pathname', location.pathname)
  // console.log('search', location.search)

  useEffect(() => {
    //navbar style
    if (location.pathname.includes('/crm') === false) {
      if (location.pathname === '/') {
        var navbar = document.getElementById('navbar')
        navbar.style.background = 'none'
      } else {
        var navbar = document.getElementById('navbar')
        navbar.style.background = '#002144'
        navbar.style.color = '#ffcc03'
      }
    }
  }, [location])
  // test path
  console.log(location.pathname.includes('/crm'))
  var crmlogin = true
  // crm route and navbar
  // when login and when don't loged / when route for user
  if (location.pathname.includes('/crm') === true) {
    if (localStorage.getItem('userInfo')) {
      let user = JSON.parse(localStorage.getItem('userInfo'))
      if (user.GroupId < 6) {
        return (
          <>
            {localStorage.getItem('Lang') === 'EN' ? (
              <>
                <div className="crmp_">
                  <CrmNavbar />
                  <AnimatePresence>
                    <Routes location={location} key={location.pathname}>
                      <Route path="/crm" element={<CrmHome />} />
                      <Route path="/crm/projects" element={<CrmProjects />} />
                    </Routes>
                  </AnimatePresence>
                </div>
              </>
            ) : (
              <>
                <div className="crmp_ ar">
                  <CrmNavbar />
                  <AnimatePresence>
                    <Routes location={location} key={location.pathname}>
                      <Route path="/crm" element={<CrmHome />} />
                      <Route path="/crm/projects" element={<CrmProjects />} />
                      <Route
                        path="/crm/projectpreview"
                        element={<CrmpreviewProjects />}
                      />
                      <Route path="/crm/pages" element={<Crmpages />} />
                      <Route path="/crm/vistores" element={<CrmVistores />} />
                      <Route
                        path="/crm/Entrepreneurs"
                        element={<Entrepreneurs />}
                      />
                      <Route path="/crm/companies" element={<Companies />} />
                      <Route
                        path="/crm/Projectfinancier"
                        element={<Projectfinancier />}
                      />
                      <Route
                        path="/crm/Projectfinancierrequest"
                        element={<Projectfinancrequest />}
                      />
                    </Routes>
                  </AnimatePresence>
                </div>
              </>
            )}
          </>
        )
      } else {
        return (
          <>
            <AnimatePresence>
              <Routes location={location} key={location.pathname}>
                <Route path="/crm" element={<CrmLogin />} />
              </Routes>
            </AnimatePresence>
          </>
        )
      }
    } else {
      return (
        <>
          <AnimatePresence>
            <Routes location={location} key={location.pathname}>
              <Route path="/crm" element={<CrmLogin />} />
            </Routes>
          </AnimatePresence>
        </>
      )
    }
  } else {
    return (
      <>
        <Navbar />
        <AnimatePresence>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/profile/:profileid" element={<Profile />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/explore" element={<Explore />} />
            <Route path="/howitworks" element={<Howitworks />} />
            <Route path="/start" element={<Start />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/project/:projectid" element={<Project />} />
            <Route path="/company/:companyid" element={<Company />} />
            <Route path="/newproject" element={<Addnewproject />}></Route>
            <Route path="/newcompany" element={<Addnewcompany />}></Route>
          </Routes>
        </AnimatePresence>
        <Header />
      </>
    )
  }
}
function Pageroutecrm() {
  const location = useLocation()
  // console.log('hash', location.hash)
  // console.log('pathname', location.pathname)
  // console.log('search', location.search)

  useEffect(() => {}, [location])

  return (
    <>
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          {/* <Route path="/" element={<Home />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/profile/:profileid" element={<Profile />} />
          <Route path="/profile" element={<Profile />} /> */}
        </Routes>
      </AnimatePresence>
    </>
  )
}
export { Pageroute, Pageroutecrm }
